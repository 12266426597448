<template>
  <div class="widget-pagination">
    <button
      class="widget-pagination-prev"
      :style="buttonStyle"
      :disabled="currentPage === 1"
      @click="$emit('load-page', currentPage - 1)">&larr;</button>

    <button
      :class="['widget-pagination-page', currentPage === 1 ? 'current' : '']"
      :style="buttonStyle"
      :key="'page-1'"
      @click="$emit('load-page', 1)">1</button>

    <span
      class="widget-pagination-parenthesis"
      v-if="excerptedPagesStartDiscontinuous">&hellip;</span>

    <button
      v-for="pageNumber in excerpedPagesArray"
      :class="['widget-pagination-page', pageNumber === currentPage ? 'current' : '']"
      :style="buttonStyle"
      :key="'page-' + pageNumber"
      @click="$emit('load-page', pageNumber)">{{pageNumber}}</button>

    <span
      class="widget-pagination-parenthesis"
      v-if="excerptedPagesEndDiscontinuous">&hellip;</span>

    <button
      :class="['widget-pagination-page', lastPageNumber === currentPage ? 'current' : '']"
      :style="buttonStyle"
      :key="'page-' + lastPageNumber"
      @click="$emit('load-page', lastPageNumber)">{{lastPageNumber}}</button>

    <button
      class="widget-pagination-next"
      :style="buttonStyle"
      :disabled="currentPage === pageCount"
      @click="$emit('load-page', currentPage + 1)">&rarr;</button>
  </div>
</template>

<script>

export default {
  props: [ 'widget', 'pageCount', 'currentPage' ],

  components: {
  },

  computed: {
    pagesArray() {
      var array = [];
      for(var i = 1; i <= this.pageCount; i++) {
        array.push(i);
      }
      return array;
    },

    pagesArrayExceptFirstAndLast() {
      return this.pagesArray.slice(1, this.pagesArray.length - 1)
    },

    excerpedPagesArray() {
      let indexMin = this.currentPage - 4
      indexMin = Math.max(indexMin, 0)
      let indexMax = indexMin + 5
      indexMax = Math.min(indexMax, this.pagesArrayExceptFirstAndLast.length)
      indexMin = Math.min(indexMin, indexMax - 5)
      return this.pagesArrayExceptFirstAndLast.slice(indexMin, indexMax)
    },

    lastPageNumber() {
      return this.pagesArray[this.pagesArray.length - 1]
    },

    excerptedPagesStartDiscontinuous() {
      return this.excerpedPagesArray[0] > 2
    },

    excerptedPagesEndDiscontinuous() {
      return this.excerpedPagesArray[this.excerpedPagesArray.length - 1] < (this.lastPageNumber - 1)
    },

    buttonStyle() {
      return `border-radius: ${this.widget.buttonBorderRadius};`;
    },

  }
}
</script>

<style scoped lang="scss">
  .widget-pagination {
    margin: 20px -5px;
    text-align: center;
    button {
      padding: 12px 24px;
      background-color: #FFF;
      color: #333;
      border: 1px solid #333;
      margin: 5px;
      width: auto;
      display: inline-block;
      &[disabled] {
        opacity: 0.3;
      }
      &.current {
        background-color: #333;
        color: #FFF;
      }
    }
    button.widget-pagination-prev, button.widget-pagination-next {
      width: 8em;
    }
    .widget-pagination-parenthesis {
      font-size: 20px;
      vertical-align: text-bottom;
      margin: 6px;
      line-height: 1.5;
    }
  }
</style>
