export default function(product, variant, groups, customer, mode) {
  // support object or id for variant
  if (typeof variant !== "object") {
    variant = product.variants.find(v => v.id === variant);
  }

  if (!groups || typeof groups.filter !== "function") {
    return mode === "discount" ? null : variant.price;
  }
  var matchingGroups = groups.filter(group => {
    if (group.customer_selection === "all_customers") {
      return true;
    }
    if (group.customer_selection === "logged_in" && customer.id) {
      return true;
    }
    if (group.customer_selection === "tags" && customer.tags) {
      for (var tag of customer.tags) {
        if (
          group.customer_tags
            .map(t => t.trim().toLowerCase())
            .indexOf(tag.trim().toLowerCase()) > -1
        ) {
          return true;
        }
      }
    }
    return false;
  });
  var ruleOrder = [
    "product_variants",
    "products",
    "product_tags",
    "collections",
    "all_products"
  ];
  for (var group of matchingGroups) {
    var sortedRules = [...group.rules].sort(function(a, b) {
      if (a.selection == b.selection) {
        return 0;
      } else {
        return ruleOrder.indexOf(a.selection) - ruleOrder.indexOf(b.selection);
      }
    });

    for (var rule of sortedRules) {
      var matches = false;

      if (rule.selection === "all_products") {
        matches = true;
      }

      if (rule.selection === "product_variants") {
        matches = rule.product_variants.find(pv => {
          return pv.variants.find(v => "" + v.object_id === "" + variant.id);
        });
      }

      if (rule.selection === "products") {
        matches = rule.products.find(p => "" + p.object_id === "" + product.id);
      }

      if (rule.selection === "product_tags") {
        for (var tag of rule.product_tags) {
          matches =
            matches ||
            product.tags
              .map(t => t.trim().toLowerCase())
              .indexOf(tag.trim().toLowerCase()) > -1;
        }
      }

      if (rule.selection === "collections") {
        for (var collection of rule.collections) {
          matches =
            matches ||
            product.collections.find(
              c => "" + c.id === "" + collection.object_id
            );
        }
      }

      if (matches) {
        let result = applyRule(variant, rule, mode);
        return result;
      }
    }
  }
  return mode === "discount" ? null : variant.price;
}

function applyRule(variant, rule, mode) {
  const variantPrice = parseFloat(variant.price);
  if (rule.pricing_type === "discount_percent") {
    if (mode === "discount") {
      return { valueType: "PERCENTAGE", value: rule.pricing_percent };
    } else {
      if (typeof variant.price === "number") {
        /* price is cents so we can round it
        ceil is the Shopify logic for calculating % discounts */
        return Math.ceil(((100 - rule.pricing_percent) / 100) * variantPrice);
      } else {
        return ((100 - rule.pricing_percent) / 100) * variantPrice;
      }
    }
  }
  if (rule.pricing_type === "discount_amount") {
    if (mode === "discount") {
      return { valueType: "FIXED_AMOUNT", value: rule.pricing_amount };
    } else {
      return variantPrice - rule.pricing_amount;
    }
  }
  if (rule.pricing_type === "price_amount") {
    if (mode === "discount") {
      return {
        valueType: "FIXED_AMOUNT",
        value: variantPrice - rule.pricing_amount
      };
    } else {
      return rule.pricing_amount;
    }
  }
}
