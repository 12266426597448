<template>
  <div
    :class="[
      'widget-header',
      showDropdown ? 'has-dropdown' : '',
      isShowingSearchInputOnMobile ? 'show-search-input-on-mobile' : '',
    ]"
  >
    <h2 class="widget-title" v-html="widget.title"></h2>

    <div class="widget-nav">
      <div class="widget-panes-dropdown" v-if="showDropdown">
        <select
          :style="`border-radius: ${widget.buttonBorderRadius};`"
          @change="dropdownChanged($event)"
        >
          <option
            v-for="(pane, index) in panes"
            :value="index"
            :key="pane.id"
            :selected="!isSearching && index === activePaneIndex"
          >
            {{ pane.title }}
          </option>
          <option v-if="isSearching" :selected="isSearching">
            {{ widget.searchResultsText }}
          </option>
        </select>
      </div>

      <div class="widget-search" v-if="widget.showSearch === true">
        <button
          class="widget-show-search-input"
          @click="showSearchInputOnMobile()"
        ></button>
        <input
          type="search"
          v-model="searchQuery"
          ref="searchQuery"
          name="instabuy-search"
          autocomplete="chrome-off"
          :placeholder="widget.searchText"
          :class="isSearching && searchIsLoading ? 'widget-spinner' : ''"
          @keyup="$emit('search-query-changed', $event.target.value)"
          @search="
            hideSearchInputOnMobileIfBlank($event.target.value);
            $emit('search-query-changed', $event.target.value);
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "widget",
    "panes",
    "activePaneIndex",
    "isSearching",
    "searchIsLoading",
  ],

  data: function () {
    return {
      searchQuery: "",
      isShowingSearchInputOnMobile: false,
    };
  },

  computed: {
    showDropdown() {
      return (
        this.widget.navFormat === "dropdown" && this.dropdownHasMultipleItems
      );
    },

    dropdownHasMultipleItems() {
      return this.panes.length > 1;
    },
  },

  methods: {
    dropdownChanged(event) {
      this.$nextTick(function () {
        this.searchQuery = "";
        this.$emit("search-query-changed", this.searchQuery);
        this.$emit("active-pane-index-changed", +event.target.value);
      });
    },

    showSearchInputOnMobile() {
      this.isShowingSearchInputOnMobile = true;
      this.$nextTick(() => this.$refs.searchQuery.focus());
    },

    hideSearchInputOnMobileIfBlank(searchQuery) {
      if (searchQuery.length === 0) {
        this.isShowingSearchInputOnMobile = false;
      }
    },
  },
};
</script>

<style lang="scss">
.widget-search {
  //display: none;
  button {
    display: none;
  }
}
</style>

<style scoped lang="scss">
.widget-header {
  padding-top: 15px;
  padding-bottom: 15px;
  h2 {
    margin-top: 10px;
    margin-bottom: 10px;
    flex-grow: 3;
    font-size: 26px;
    text-transform: none;
    letter-spacing: 0;
    font-weight: 600;
    text-align: left;
    &::after {
      display: none;
    }
  }
  display: flex;
  &.has-dropdown {
    h2 {
      width: 100%;
    }
    display: block;
  }
}

.widget-nav {
  display: flex;
  /*flex-grow: 1;*/
  margin: 0 -10px;
  justify-content: space-between;
  flex-direction: row;
  .widget-panes-dropdown {
    margin: 5px 10px;
    select {
      padding-top: 10px;
      padding-left: 18px;
      padding-bottom: 10px;
      padding-right: 28px;
      appearance: none;
      -webkit-appearance: none;
      background-position: right center;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTJweCIgaGVpZ2h0PSI3cHgiIHZpZXdCb3g9IjAgMCAxMiA3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPGcgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgICAgIDxwYXRoIGQ9Ik02LjA1MDIzMTkzLDYuNzEyMjYwNDQgTDYuMDI3NjkyNzksNi43MzQ3OTk1OCBMNS42NzQxMzk0LDYuMzgxMjQ2MTkgTDEuMTQ2NDQ2NjEsMS44NTM1NTMzOSBMMC43OTI4OTMyMTksMS41IEwxLjUsMC43OTI4OTMyMTkgTDEuODUzNTUzMzksMS4xNDY0NDY2MSBMNi4wMjgyNTkyOCw1LjMyMTE1MjUgTDEwLjIyNDkzNzgsMS4xMjQ0NzM5NSBMMTAuNTc4NDkxMiwwLjc3MDkyMDU2MyBMMTEuMjg1NTk4LDEuNDc4MDI3MzQgTDEwLjkzMjA0NDYsMS44MzE1ODA3MyBMNi40MDQzNTE4MSw2LjM1OTI3MzUzIEw2LjA1MDc5ODQyLDYuNzEyODI2OTIgTDYuMDUwMjMxOTMsNi43MTIyNjA0NCBaIiBmaWxsPSIjMDAwIj48L3BhdGg+CiAgICA8L2c+Cjwvc3ZnPgo=);
      background-repeat: no-repeat;
      background-position: right 10px center;
      background-size: auto;
      line-height: 1.2;
      text-indent: 0.01px;
      text-overflow: "";
      border: 1px solid #333;
      background-color: #fff;
      color: #333;
      cursor: pointer;
      border-radius: 3px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      outline: none;
      margin-bottom: 0px;
    }
  }
  .widget-search {
    /*flex-grow: 1;*/
    margin: 5px 10px;
    button {
      outline: none;
      height: 41px;
      width: 41px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCAzMC4yMzkgMzAuMjM5IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMC4yMzkgMzAuMjM5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTIwLjE5NCwzLjQ2Yy00LjYxMy00LjYxMy0xMi4xMjEtNC42MTMtMTYuNzM0LDBjLTQuNjEyLDQuNjE0LTQuNjEyLDEyLjEyMSwwLDE2LjczNSAgIGM0LjEwOCw0LjEwNywxMC41MDYsNC41NDcsMTUuMTE2LDEuMzRjMC4wOTcsMC40NTksMC4zMTksMC44OTcsMC42NzYsMS4yNTRsNi43MTgsNi43MThjMC45NzksMC45NzcsMi41NjEsMC45NzcsMy41MzUsMCAgIGMwLjk3OC0wLjk3OCwwLjk3OC0yLjU2LDAtMy41MzVsLTYuNzE4LTYuNzJjLTAuMzU1LTAuMzU0LTAuNzk0LTAuNTc3LTEuMjUzLTAuNjc0QzI0Ljc0MywxMy45NjcsMjQuMzAzLDcuNTcsMjAuMTk0LDMuNDZ6ICAgIE0xOC4wNzMsMTguMDc0Yy0zLjQ0NCwzLjQ0NC05LjA0OSwzLjQ0NC0xMi40OTIsMGMtMy40NDItMy40NDQtMy40NDItOS4wNDgsMC0xMi40OTJjMy40NDMtMy40NDMsOS4wNDgtMy40NDMsMTIuNDkyLDAgICBDMjEuNTE3LDkuMDI2LDIxLjUxNywxNC42MywxOC4wNzMsMTguMDc0eiIgZmlsbD0iIzMzMzMzMyIvPgo8L2c+Cjwvc3ZnPgo=);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 16px 16px;
      border: 1px solid #333;
      background-color: #fff;
      color: #333;
      border-radius: 100px;
      margin-bottom: 0px;
    }
    input {
      width: 100%;
      outline: none;
      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 36px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCAzMC4yMzkgMzAuMjM5IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMC4yMzkgMzAuMjM5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTIwLjE5NCwzLjQ2Yy00LjYxMy00LjYxMy0xMi4xMjEtNC42MTMtMTYuNzM0LDBjLTQuNjEyLDQuNjE0LTQuNjEyLDEyLjEyMSwwLDE2LjczNSAgIGM0LjEwOCw0LjEwNywxMC41MDYsNC41NDcsMTUuMTE2LDEuMzRjMC4wOTcsMC40NTksMC4zMTksMC44OTcsMC42NzYsMS4yNTRsNi43MTgsNi43MThjMC45NzksMC45NzcsMi41NjEsMC45NzcsMy41MzUsMCAgIGMwLjk3OC0wLjk3OCwwLjk3OC0yLjU2LDAtMy41MzVsLTYuNzE4LTYuNzJjLTAuMzU1LTAuMzU0LTAuNzk0LTAuNTc3LTEuMjUzLTAuNjc0QzI0Ljc0MywxMy45NjcsMjQuMzAzLDcuNTcsMjAuMTk0LDMuNDZ6ICAgIE0xOC4wNzMsMTguMDc0Yy0zLjQ0NCwzLjQ0NC05LjA0OSwzLjQ0NC0xMi40OTIsMGMtMy40NDItMy40NDQtMy40NDItOS4wNDgsMC0xMi40OTJjMy40NDMtMy40NDMsOS4wNDgtMy40NDMsMTIuNDkyLDAgICBDMjEuNTE3LDkuMDI2LDIxLjUxNywxNC42MywxOC4wNzMsMTguMDc0eiIgZmlsbD0iIzMzMzMzMyIvPgo8L2c+Cjwvc3ZnPgo=);
      background-repeat: no-repeat;
      background-position: left 14px center;
      background-size: 16px 16px;
      line-height: 1.2;
      text-indent: 0.01px;
      text-overflow: "";
      border: 1px solid #333;
      background-color: #fff;
      color: #333;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      border-radius: 100px;
      box-sizing: border-box;
      margin-bottom: 0px;

      &::-webkit-search-cancel-button {
        -webkit-appearance: searchfield-cancel-button;
      }
    }
  }
}
</style>

<style lang="scss">
@element .instabuy-widget and (max-width: 600px) {
  .widget-header.show-search-input-on-mobile {
    display: block;
    .widget-nav {
      flex-direction: column-reverse;
      button {
        display: none;
      }
      input {
        display: block;
      }
    }
  }

  .widget-nav {
    .widget-panes-dropdown {
      flex-grow: 1;
      select {
        width: 100%;
      }
    }

    .widget-search {
      display: block;
      input {
        display: none;
      }
      button {
        display: block;
      }
    }
  }
}

@element .instabuy-widget and (min-width: 601px) {
  .widget-nav {
    .widget-search {
      display: block;
      min-width: 250px;
      max-width: 400px;
      button {
        display: none;
      }
    }
  }
}
</style>
