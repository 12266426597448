<template>
  <div class="product-pane">
    <ProductTable
      :stagedProducts="stagedProducts"
      :widget="widget"
      :productCount="1"
    >
      <Product
        :product="product"
        :key="product.id"
        :widget="widget"
        :stagedProducts="stagedProducts"
        :pricingRules="pricingRules"
        :customer="customer"
        :highlightSearchQuery="highlightSearchQuery"
        :cart="cart"
        @add-to-cart="$emit('add-to-cart', $event)"
        @re-rendered="$emit('re-rendered', $event)"
      ></Product>
    </ProductTable>
  </div>
</template>

<script>
import Product from "./Product.vue";
import ProductTable from "./ProductTable.vue";

export default {
  props: [
    "title",
    "product",
    "widget",
    "stagedProducts",
    "pricingRules",
    "customer",
    "highlightSearchQuery",
    "cart",
  ],
  components: {
    Product,
    ProductTable,
  },

  data() {
    return {};
  },
};
</script>

<style scoped>
</style>
