import Vue from "vue";
import WidgetApp from "./WidgetApp.vue";
import ShopifyCartApp from "./ShopifyCartApp.vue";
import InstabuyCartApp from "./InstabuyCartApp.vue";
// import InstabuyCart from './InstabuyCart.vue'
//console.log('InstabuyCart', InstabuyCart)
import axios from "axios";
import VueAxios from "vue-axios";
import VueJsonp from "vue-jsonp";
import "whatwg-fetch";
import Cookie from "js.cookie";

Vue.use(VueJsonp, 5000);
Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

const configApi = {
  development: `https://config-development.useinstabuy.com/`,
  production: `https://config.useinstabuy.com/`
}[process.env.NODE_ENV];

window.InstaBuy = window.InstaBuy || {};

window.InstaBuy.widgetDefaults = {
  shopUrl: "",
  moneyFormat: "$%7B%7Bamount%7D%7D",
  displayAllProducts: false,
  displayAllCollections: false,
  expandVariants: false,
  showQuantityInput: true,
  showSku: false,
  showQuantityButtons: false,
  showWeight: false,
  showAvailability: false,
  showSearch: true,
  showVariantsButtonText: "Show options",
  addToCartButtonText: "Add to cart",
  unavailableButtonText: "Unavailable",
  fromText: "From",
  availableText: "available",
  searchText: "Search all products",
  searchResultsText: "Search results",
  showVariantsButtonBackgroundColor: "#FFFFFF",
  showVariantsButtonTextColor: "#333333",
  addToCartButtonBackgroundColor: "#00A3F0",
  addToCartButtonTextColor: "#FFFFFF",
  viewCartText: "View cart",
  checkoutText: "Checkout",
  buttonPosition: "row",
  buttonFontSize: "16px",
  buttonBorderRadius: "3px",
  buttonPadding: "8px 16px",
  navFormat: "dropdown",
  productHandles: "",
  collectionHandles: ""
};

function proxiedShopUrl(url) {
  var localeStr = "";
  if (
    window.Shopify &&
    window.Shopify.locale &&
    window.location.href.indexOf(
      "/" + window.Shopify.locale.toLowerCase() + "/"
    ) > -1
  ) {
    localeStr = "/" + window.Shopify.locale.toLowerCase();
  }

  // If we're a Shopify shop (i.e. not embedded somewhere else) use the current hostname
  if (window.Shopify && window.Shopify.shop) {
    return localeStr;
  }

  var hostname = url.replace(/^[a-zA-Z]+:\/\//, "");
  if (window.location.hostname !== hostname) {
    url = "https://shopdata.io/" + hostname + localeStr;
  }
  return url;
}

function stripTrailingSlash(url) {
  if (url && url[url.length - 1] === "/") {
    url = url.slice(0, -1);
  }
  return url;
}

function initializeInstabuyCartApp(opts) {
  if (window.InstaBuy.debug) {
    console.log("initializeInstabuyCartApp");
  }
  if (window.InstaBuy.instabuyCartApp) {
    return Promise.resolve(window.InstaBuy.instabuyCartApp);
  }
  if (!window.InstaBuy.instabuyCartApp && !opts.preview) {
    if (window.InstaBuy.debug) {
      console.log(
        "initializeInstabuyCartApp - no instabuyCartApp, creating..."
      );
    }
    var instabuyCartEl = document.getElementById("instabuy-custom-cart");
    if (!instabuyCartEl) {
      instabuyCartEl = document.createElement("div");
      instabuyCartEl.id = "instabuy-custom-cart";
      document.body.appendChild(instabuyCartEl);
    }

    window.InstaBuy.instabuyCartApp = new Vue({
      data() {
        return {
          pricingRules: {},
          customer: {
            id: null,
            email: null,
            tags: []
          },
          moneyFormat: "",
          shopifyDomain: null,
          notesEnabled: false,
          notesName: "",
          notesRequired: false,
          notesHelpText: "",
          checkoutAction: "checkout",
          checkoutThanksTitle: "",
          checkoutThanksText: ""
        };
      },
      mounted() {},
      render: function(createElement) {
        return createElement(InstabuyCartApp, {
          props: {
            customer: window.InstaBuy.state.customer,
            pricingRules: window.InstaBuy.state.pricingRules,
            moneyFormat: window.InstaBuy.state.moneyFormat,
            shopifyDomain: window.InstaBuy.state.shopifyDomain,
            notesEnabled: this.notesEnabled,
            notesName: this.notesName,
            notesRequired: this.notesRequired,
            notesHelpText: this.notesHelpText,
            checkoutAction: this.checkoutAction,
            checkoutThanksTitle: this.checkoutThanksTitle,
            checkoutThanksText: this.checkoutThanksText,
            translations: window.InstaBuy.state.translations
          }
        });
      }
    });

    return fetch(
      `${
        window.Shopify && window.Shopify.routes && window.Shopify.routes.root
          ? window.Shopify.routes.root
          : "/"
      }cart?view=instabuy-data`
    )
      .then(response => response.json())
      .then(data => {
        window.InstaBuy.state.currency = data.currency;
        window.InstaBuy.state.shopCurrency =
          data.shop_currency || window.Shopify.currency.active; // fallback to Shopify currency if the cart template doesn't have a currency
        if (!window.InstaBuy.state.customer.id) {
          window.InstaBuy.state.customer.id ||= data.customer_id;
          window.InstaBuy.state.customer.email ||= data.customer_email;
          window.InstaBuy.state.customer.tags = data.customer_tags;
        }
        window.InstaBuy.state.moneyFormat = data.money_format;
        window.InstaBuy.state.shopifyDomain = data.shopify_domain;
        window.InstaBuy.instabuyCartApp.$mount();
        instabuyCartEl.appendChild(window.InstaBuy.instabuyCartApp.$el);
      });
  }
}

window.InstaBuy.init = function(opts) {
  opts = opts || {};

  window.InstaBuy.state = new Vue({
    data: {
      moneyFormat: "${{amount}}",
      customer: {},
      shopifyDomain: null,
      currency: null,
      shopCurrency: null,
      pricingRules: [],
      // defaults:
      translations: [
        {
          language: null,
          default: true,
          cart_title: "Your order",
          cart_item: "{{number}} item",
          cart_items: "{{number}} items",
          cart_expand: "Show details",
          cart_contract: "Hide details",
          cart_checkout: "Checkout",
          minimum_order_value_title: "Minimum order value not met",
          minimum_order_value_text:
            "Your order total must be at least {{amount}} to checkout"
        }
      ]
    }
  });

  var widgets = document.querySelectorAll("[data-instabuy]");

  // TODO support ID-only widgets
  // var widgetsWithCart = Array.prototype.filter.call(widgets, (widget) => widget.dataset.showCart !== 'false');

  var mainWidget = widgets[0];

  if (!window.InstaBuy.shopifyCartApp && !opts.preview && widgets.length > 0) {
    var cartEl = document.getElementById("instabuy-cart");
    if (!cartEl) {
      cartEl = cartEl || document.createElement("div");
      document.body.appendChild(cartEl);
    }
    // cartEl.style.display = widgetsWithCart.length > 0 ? 'block' : 'none';
    var firstWidgetData = JSON.parse(JSON.stringify(mainWidget.dataset));
    var shopUrl = firstWidgetData.shopUrl;
    shopUrl = stripTrailingSlash(shopUrl);

    // If we're a Shopify shop (i.e. not embedded somewhere else) use the current hostname
    if (window.Shopify && window.Shopify.shop) {
      shopUrl = "";
    }

    var buttonBackgroundColor =
      firstWidgetData.addToCartButtonBackgroundColor ||
      window.InstaBuy.widgetDefaults.addToCartButtonBackgroundColor;
    var buttonBorderColor = buttonBackgroundColor;
    if (buttonBackgroundColor.toUpperCase().indexOf("FFFFFF") > 0) {
      buttonBorderColor = `#333333`;
    }

    var buttonTextColor =
      firstWidgetData.addToCartButtonTextColor ||
      window.InstaBuy.widgetDefaults.addToCartButtonTextColor;
    if (window.InstaBuy.shopifyCartApp) {
      window.InstaBuy.shopifyCartApp.$destroy();
    }

    window.InstaBuy.shopifyCartApp = new Vue({
      render: createElement =>
        createElement(ShopifyCartApp, {
          props: {
            shopUrl,
            buttonBackgroundColor,
            buttonBorderColor,
            buttonTextColor,
            moneyFormat: window.InstaBuy.state.moneyFormat
          }
        })
    }).$mount();
    cartEl.appendChild(window.InstaBuy.shopifyCartApp.$el);
  }

  // mount widgets

  for (var i = 0; i < widgets.length; i++) {
    var widget = widgets[i];

    if (widget.dataset.initialized && !opts.preview) {
      continue;
    }

    widget.dataset.initialized = "true";

    // We attacht the Vue app to a div inside the widget element to avoid losing the dataset
    var widgetContent = document.createElement("div");
    widget.appendChild(widgetContent);

    var widgetData;
    // if we have the shopUrl, we are going to use the embed code directly
    // legacy
    if (widget.dataset.shopUrl) {
      widgetData = {
        widget: Object.assign(
          {},
          window.InstaBuy.widgetDefaults,
          JSON.parse(JSON.stringify(widget.dataset))
        )
      };
      mountWidget(widgetData, widget, opts);
      // without the shopUrl, we load the config from the API
    } else {
      // if we have a cookied token, attempt to use it
      // otherwise, load the config without a token
      let clientToken = null;
      let authCookie = Cookie.get("instabuy");
      if (
        authCookie &&
        authCookie.auth &&
        authCookie.auth[widget.dataset.widgetId]
      ) {
        clientToken = authCookie.auth[widget.dataset.widgetId];
      }
      // one of four things will happen:
      // - we have a token and it's valid, and we get the full config
      // - we don't have a token, and the page is not password protected, and we get the full config
      // - we have a token and it's invalid, and we get a 40x
      // - we don't have a token, and the page is password protected, and we get the unauth'd config

      loadAndMountWidget(widget, clientToken, opts);
    }
  }
};

function loadAndMountWidget(widgetEl, clientToken, opts) {
  var instabuyCartAppPromise = initializeInstabuyCartApp(opts);
  if (window.InstaBuy.debug) {
    console.log(
      "loadAndMountWidget",
      widgetEl.dataset.widgetId,
      clientToken,
      instabuyCartAppPromise
    );
  }

  axios
    .get(
      `${configApi}render/${widgetEl.dataset.widgetId}${
        clientToken ? `/${clientToken}` : ""
      }.json`
    )
    .then(function(res) {
      var widgetData = {};
      widgetData.pricingRules = res.data.pricing_rules;
      // prefer dataset
      widgetData.widget = Object.assign(
        res.data.widget,
        JSON.parse(JSON.stringify(widgetEl.dataset))
      );

      // Assign translations to state
      if (res.data.translations) {
        InstaBuy.state.translations = res.data.translations;
      }

      // wait for the instabuy cart data to load
      // so that we have the currency
      instabuyCartAppPromise
        .then(() => {
          if (
            res.data.widget.customPricing &&
            window.InstaBuy.state.currency &&
            window.InstaBuy.state.shopCurrency &&
            window.InstaBuy.state.currency !==
              window.InstaBuy.state.shopCurrency
          ) {
            // change currency to store currency
            return fetch(
              `${
                window.Shopify &&
                window.Shopify.routes &&
                window.Shopify.routes.root
                  ? window.Shopify.routes.root
                  : "/"
              }cart?view=instabuy-data&currency=${
                window.InstaBuy.state.shopCurrency
              }`
            );
          }
        })
        .then(() => {
          mountWidget(widgetData, widgetEl, opts);
        });
    })
    .catch(function(err) {
      console.log(err);
      if (clientToken) {
        // reset token in cookie
        cookieToken({ widget_id: widgetEl.dataset.widgetId, token: null });
        // reload widget without invalid token
        loadAndMountWidget(widgetEl, null, opts);
      }
    });
}

function cookieToken(authData) {
  let authCookie = Cookie.get("instabuy");
  if (!authCookie) {
    authCookie = {};
  }
  if (!authCookie.auth) {
    authCookie.auth = {};
  }
  authCookie.auth[authData.widget_id] = authData["token"];
  Cookie.set("instabuy", authCookie);
}

function mountWidget(widgetData, widgetEl, opts) {
  widgetData.widget.showVariantsButtonBorderColor =
    widgetData.widget.showVariantsButtonBackgroundColor;
  if (
    widgetData.widget.showVariantsButtonBackgroundColor &&
    widgetData.widget.showVariantsButtonBackgroundColor
      .toUpperCase()
      .indexOf("FFFFFF") > 0
  ) {
    widgetData.widget.showVariantsButtonBorderColor = `#333333`;
  }

  widgetData.widget.addToCartButtonBorderColor =
    widgetData.widget.addToCartButtonBackgroundColor;
  if (
    widgetData.widget.addToCartButtonBackgroundColor &&
    widgetData.widget.addToCartButtonBackgroundColor
      .toUpperCase()
      .indexOf("FFFFFF") > 0
  ) {
    widgetData.widget.addToCartButtonBorderColor = `#333333`;
  }

  if (!widgetData.widget.buttonPadding) {
    widgetData.widget.buttonPadding = `${widgetData.widget.buttonPaddingVertical}px ${widgetData.widget.buttonPaddingHorizontal}px`;
  }

  if (typeof widgetData.widget.buttonBorderRadius === "number") {
    widgetData.widget.buttonBorderRadius = `${
      widgetData.widget.buttonBorderRadius >= 30
        ? 100
        : widgetData.widget.buttonBorderRadius
    }px`;
  }

  widgetData.widget.shopUrl = stripTrailingSlash(widgetData.widget.shopUrl);

  // unstringify embed code booleans
  for (var prop in widgetData.widget) {
    if (widgetData.widget[prop] === "true") {
      widgetData.widget[prop] = true;
    }
    if (widgetData.widget[prop] === "false") {
      widgetData.widget[prop] = false;
    }
  }

  if (widgetData.widget.shopUrl) {
    widgetData.widget.proxiedShopUrl = proxiedShopUrl(
      widgetData.widget.shopUrl
    );
  }

  let customer;
  // no instabuyCartApp in preview
  if (window.InstaBuy.instabuyCartApp) {
    // copy pricing rules TO state
    window.InstaBuy.state.pricingRules = widgetData.pricingRules;

    // console.log("widgetData", widgetData.widget.notesEnabled);

    window.InstaBuy.instabuyCartApp.notesEnabled =
      widgetData.widget.notesEnabled;
    window.InstaBuy.instabuyCartApp.notesName = widgetData.widget.notesName;
    window.InstaBuy.instabuyCartApp.notesRequired =
      widgetData.widget.notesRequired;
    window.InstaBuy.instabuyCartApp.notesHelpText =
      widgetData.widget.notesHelpText;
    window.InstaBuy.instabuyCartApp.checkoutAction =
      widgetData.widget.checkoutAction;
    window.InstaBuy.instabuyCartApp.checkoutThanksTitle =
      widgetData.widget.checkoutThanksTitle;
    window.InstaBuy.instabuyCartApp.checkoutThanksText =
      widgetData.widget.checkoutThanksText;
  }

  new Vue({
    render: createElement =>
      createElement(WidgetApp, {
        props: {
          widget: widgetData.widget,
          customer: window.InstaBuy.state.customer,
          pricingRules: window.InstaBuy.state.pricingRules
        },
        on: {
          "widget-authenticated": function(authData) {
            cookieToken(authData);
            loadAndMountWidget(widgetEl, authData.token, opts);
          }
        }
      })
  }).$mount(widgetEl.querySelector("div"));
}

window.InstaBuy.init();
