export default function formatMoney(e, t) {
  t = decodeURIComponent(t);

  // bugfix by TM
  if(typeof e === "string" && e.indexOf('.') === -1) { e = "" + e + ".00"; }

  function n(e, t) { return "undefined" == typeof e ? t : e }

  function r(e, t, r, i) {
    if(t = n(t, 2), r = n(r, ","), i = n(i, "."), isNaN(e) || null == e) return 0;
    e = (e / 100).toFixed(t);
    var o = e.split("."),
      a = o[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + r),
      s = o[1] ? i + o[1] : "";
    return a + s
  }
  "string" == typeof e && (e = e.replace(".", ""));
  var i = "",
    o = /\{\{\s*(\w+)\s*\}\}/,
    a = t || this.money_format || '${{amount}}';
  switch(a.match(o)[1]) {
    case "amount":
      i = r(e, 2);
      break;
    case "amount_no_decimals":
      i = r(e, 0);
      break;
    case "amount_with_apostrophe_separator":
      i = r(e, 2, "'", ".");
      break;
    case "amount_with_comma_separator":
      i = r(e, 2, ".", ",");
      break;
    case "amount_no_decimals_with_comma_separator":
      i = r(e, 0, ".", ",");
      break;
    case "amount_no_decimals_with_space_separator":
      i = r(e, 0, " ")
  }
  return a.replace(o, i);
}
