<template>
  <div class="widget-pane widget-search-results">
    <ProductTable
      :stagedProducts="stagedProducts"
      :widget="widget"
      :productCount="products.length"
    >
      <Product
        v-for="product in products"
        :product="product"
        :key="product.id"
        :widget="widget"
        :highlightSearchQuery="searchQuery"
        :stagedProducts="stagedProducts"
        @re-rendered="$emit('re-rendered', $event)"
        @add-to-cart="$emit('add-to-cart', $event)"
      ></Product>
    </ProductTable>
  </div>
</template>

<script>
import Product from "./Product.vue";
import ProductTable from "./ProductTable.vue";
import debounce from "debounce";

export default {
  props: ["searchQuery", "widget", "searchIsLoading", "stagedProducts"],
  components: {
    Product,
    ProductTable,
  },

  data() {
    return {
      products: [],
    };
  },

  watch: {
    searchQuery() {
      this.$emit("search-is-loading", true);
      this.debouncePerformSearch();
    },
  },

  mounted() {
    this.$emit("search-is-loading", true);
    this.debouncePerformSearch();
  },

  methods: {
    debouncePerformSearch: debounce(function () {
      this.fetchPage(1);
    }, 500),

    fetchPage(page) {
      var interpolatedSearchQuery = this.searchQuery
        .split(" ")
        .map((word) => `${word}*`)
        .join(" ");
      fetch(
        `${
          this.widget.proxiedShopUrl
        }/search.json?type=product&q=${encodeURIComponent(
          interpolatedSearchQuery
        )}&view=instabuy-data&page=${page}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (page == 1) {
            this.products = [];
          }

          if (
            window.InstaBuy &&
            typeof window.InstaBuy.filterVariant === "function"
          ) {
            var filteredProducts = [];
            var products = data.products;
            if (typeof products === "undefined") {
              return [];
            }
            for (var i = 0; i < products.length; i++) {
              var product = products[i];
              var filteredVariants = [];
              for (var j = 0; j < product.variants.length; j++) {
                if (
                  window.InstaBuy.filterVariant.call(
                    this,
                    product.variants[j]
                  ) !== false
                ) {
                  filteredVariants.push(product.variants[j]);
                }
              }
              if (filteredVariants.length > 0) {
                product.variants = filteredVariants;
                if (typeof window.InstaBuy.filterProduct === "function") {
                  if (window.InstaBuy.filterProduct.call(this, product)) {
                    filteredProducts.push(product);
                  }
                } else {
                  filteredProducts.push(product);
                }
              }
            }
            this.products = this.products.concat(filteredProducts);
          } else {
            this.products = this.products.concat(data.products);
          }

          this.productsCount = data.items;
          if (page < data.pages) {
            this.fetchPage(page + 1);
          } else {
            this.$emit("search-is-loading", false);
            this.$emit("re-rendered");
          }
        });
    },
  },
};
</script>

<style scoped>
</style>
