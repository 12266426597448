<template>
  <tr class="widget-product-mobile widget-variant-mobile" v-show="show">
    <ProductQuantityCell
      :value="quantity"
      @input="updateQuantity"
      :hasMultipleVariants="false"
      :quantityInputStyle="quantityInputStyle"
      :product="product"
      :widget="widget"
      :variant="variant"
      :buttonBorderRadius="widget.buttonBorderRadius"
      :showQuantityButtons="widget.showQuantityButtons"
      :showQuantityInput="widget.showQuantityInput"
    ></ProductQuantityCell>
    <ProductButtonCell
      :hasMultipleVariants="false"
      :variant="variant"
      :product="product"
      :widget="widget"
      :quantity="quantity"
      :colspan="2"
      @add-to-cart="$emit('add-to-cart', $event)"
    ></ProductButtonCell>
  </tr>
</template>

<script>
import ProductQuantityCell from "./ProductQuantityCell.vue";
import ProductButtonCell from "./ProductButtonCell.vue";
import highlight from "../lib/highlight.js";

export default {
  props: [
    "show",
    "variant",
    "widget",
    "product",
    "addToCartButtonStyle",
    "unavailableButtonStyle",
    "quantityInputStyle",
    "highlightSearchQuery",
  ],

  components: {
    ProductQuantityCell,
    ProductButtonCell,
  },

  data: function () {
    return {
      quantity:
        this.widget.buttonPosition !== "row"
          ? 0
          : window.InstaBuy &&
            typeof window.InstaBuy.defaultQuantity != "undefined"
          ? window.InstaBuy.defaultQuantity
          : 1,
    };
  },

  computed: {
    quantityAvailable() {
      if (
        this.variant.inventory_management === "shopify" &&
        (this.variant.inventory_policy !== "continue" ||
          this.variant.inventory_quantity > 0)
      ) {
        if (
          this.variant.inventory_quantity > 0 ||
          window.InstaBuy.showZeroAvailability
        ) {
          return this.variant.inventory_quantity;
        }
      }
      return null;
    },
  },

  methods: {
    updateQuantity(value) {
      this.quantity = value;
    },
    clear() {
      this.quantity = 0;
    },
    highlight,
  },

  created() {
    this.$root.$on("clear-quantity", this.clear);
  },

  beforeDestroy() {
    this.$root.$off("clear-quantity", this.clear);
  },
};
</script>

<style scoped>
</style>
