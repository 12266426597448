<template>
  <div>
    <table class="instabuy-product-table" cellspacing="0" cellpadding="0">
      <slot></slot>
      <tbody>
      <tr v-if="widget.buttonPosition === 'bottom' && productCount > 0">
        <td class="widget-add-to-cart-button-bottom" colspan="5">
          <button 
            :class="['widget-add-to-cart', addingToCartIsActive ? 'adding' : '', addedToCartIsActive ? 'active' : '']"
            :disabled="productsToAdd.length === 0"
            :style="addToCartButtonStyle"
            @click="addToCartAll">
            <div class="widget-add-to-cart-success">
              <svg
                :style="addToCartButtonIconStyle"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 490.05 490.05"
                style="enable-background:new 0 0 490.05 490.05;"
                xml:space="preserve"
              >
                <g>
                  <g>
                    <path
                      d="M418.275,418.275c95.7-95.7,95.7-250.8,0-346.5s-250.8-95.7-346.5,0s-95.7,250.8,0,346.5S322.675,513.975,418.275,418.275
              z M157.175,207.575l55.1,55.1l120.7-120.6l42.7,42.7l-120.6,120.6l-42.8,42.7l-42.7-42.7l-55.1-55.1L157.175,207.575z"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div class="widget-adding-to-cart">
              <svg
                :style="addToCartButtonIconStyle"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 120 30"
                style="enable-background:new 0 0 490.05 490.05;"
                xml:space="preserve"
              >
                <circle cx="15" cy="15" r="15">
                    <animate attributeName="r" from="15" to="15"
                            begin="0s" dur="0.8s"
                            values="15;9;15" calcMode="linear"
                            repeatCount="indefinite" />
                    <animate attributeName="fill-opacity" from="1" to="1"
                            begin="0s" dur="0.8s"
                            values="1;.5;1" calcMode="linear"
                            repeatCount="indefinite" />
                </circle>
                <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                    <animate attributeName="r" from="9" to="9"
                            begin="0s" dur="0.8s"
                            values="9;15;9" calcMode="linear"
                            repeatCount="indefinite" />
                    <animate attributeName="fill-opacity" from="0.5" to="0.5"
                            begin="0s" dur="0.8s"
                            values=".5;1;.5" calcMode="linear"
                            repeatCount="indefinite" />
                </circle>
                <circle cx="105" cy="15" r="15">
                    <animate attributeName="r" from="15" to="15"
                            begin="0s" dur="0.8s"
                            values="15;9;15" calcMode="linear"
                            repeatCount="indefinite" />
                    <animate attributeName="fill-opacity" from="1" to="1"
                            begin="0s" dur="0.8s"
                            values="1;.5;1" calcMode="linear"
                            repeatCount="indefinite" />
                </circle>
            </svg>
            </div>
            <span>{{widget.addToCartButtonText}}</span>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
    <hr class="instabuy-hr" />
  </div>
</template>

<script>
import { EventBus } from "../EventBus.js";
export default {
  props: ["widget", "productCount", "stagedProducts"],
  components: {
  },
  data() {
    return {
      products: {},
      addingToCartIsActive: false,
      addedToCartIsActive: false
    };
  },
  methods: {

    addToCartAll() {
      if(window.InstaBuy && window.InstaBuy.addToCartAll) {
        this.setAddingToCartActive(true)
        window.InstaBuy.addToCartAll.call(this, this.productsToAdd)
      } else {
        this.setAddingToCartActive()
        EventBus.$emit(
          "shopify-add-to-cart-all",
          this.productsToAdd,
          this.clearAddingToCartActive.bind(this))
      }
      this.$nextTick().then(() => {
        this.$root.$emit('clear-quantity');
        this.products = {};
      });
    },

    setAddingToCartActive(autoClear) {
      this.addingToCartIsActive = true;
      if(autoClear) {
        setTimeout(this.clearAddingToCartActive, 1200)
      }
    },

    clearAddingToCartActive() {
      this.addingToCartIsActive = false
      this.setAddedToCartActive()
    },

    setAddedToCartActive() {
      this.addedToCartIsActive = true;
      setTimeout(this.clearAddedToCartActive, 1200)
    },

    clearAddedToCartActive() {
      this.addedToCartIsActive = false
    }

  },
  computed: {
    addToCartButtonStyle() {
      return `background-color: ${this.widget.addToCartButtonBackgroundColor};
      color: ${this.widget.addToCartButtonTextColor};
      border-radius: ${this.widget.buttonBorderRadius};
      padding: ${this.widget.buttonPadding};
      font-size: ${this.widget.buttonFontSize};
      border: 1px solid ${this.widget.addToCartButtonBorderColor};`;
    },
    addToCartButtonIconStyle() {
      return `fill: ${this.widget.addToCartButtonTextColor};`;
    },
    productsToAdd() {
      return Object.keys(this.stagedProducts).map(variantId => { return { variantId, quantity: this.stagedProducts[variantId]} }).filter(product => product.quantity > 0);
    }
  },
};
</script>

<style lang="scss">

  hr.instabuy-hr {
    border-top: 2px solid #DDD;
    border-bottom: none;
    height: 0px;
    margin: 0;
    width: 100%;
  }

  table.instabuy-product-table {
    border: none;
    margin-bottom: 0;
    background-color: transparent;
    table-layout: auto !important;
    white-space: normal;

    tr:first-child th, tr:first-child td { position: initial; } /* Brooklyn insanity */
    tr:first-child th:after, tr:first-child td:after { display: none; } /* Brooklyn insanity */

    tr { border: none; }
    td {
      border: none;
      border-top: 1px solid #DDD;
      vertical-align: middle;
      padding: 12px 6px;
    }
    tr.widget-variant-main, tr.widget-variant-mobile, .widget-variant-desktop {
      td:not(.widget-product-title) {
        border-top: none;
      }
    }
    tr.widget-product-description td {
      border-top: none;
    }
    td.widget-product-image {
      padding: 5px 0px;
      text-align: center;
      width: 93px;
      max-width: 93px;
      font-size: 0px;
      line-height: 0;
      vertical-align: top;

      &:not(:empty) {
        min-width: 87px;
        padding-right: 12px;
      }
      img {
        max-width: 75px;
        max-height: 75px;
      }
    }

    td.widget-product-title {
      width: 100%;
      h4 {
        padding: 8px 8px 8px 0;
        margin-bottom: 0;
        margin-top: 0;
        font-size: 18px;
        text-transform: none;
        letter-spacing: 0;
        font-weight: 600;
        text-align: left;

        a {
          color: inherit;
          text-decoration: none;
          &::after { display: none; }
        }

        &:hover {
          img.widget-view-product-icon { opacity: 0.8; }
        }
        img.widget-view-product-icon {
          width: 16px;
          height: 16px;
          border: none;
          opacity: 0;
          vertical-align: middle;
          margin-left: 8px;
          margin-top: -6px;
          transition: opacity 0.2s;
          margin-bottom: 0;
          display: inline-block;
        }
      }
      p.widget-product-sku {
        margin-top: -4px;
      }
    }

    p.widget-product-price-mobile, p.widget-product-price {
      float: right;
      margin-left: 20px;
      font-weight: normal;
      text-align: right;
      margin-top: 0;
      margin-bottom: 0;
    }

    .widget-product-sku {
      font-size: 12px;
      opacity: 0.8;
    }

    td.widget-product-price {
      white-space: nowrap;
      width: 0%;
      font-size: 18px;
      padding-right: 14px;
    }

    td.widget-quantity {
      white-space: nowrap;
      text-align: center;
      padding: 12px 8px;
    }

    .widget-quantity-input-wrapper {
      display: flex;
    }

    input.widget-quantity-input {
      outline: none;
      width: 4em;
      line-height: 1.5;
      border: 1px solid rgba(61,66,70,0.85);
      background-color: #fff;
      color: #000;
      max-width: 100%;
      margin: 0;
      height: auto;
      &.widget-quantity-input-with-buttons {
        width: 3em;
        min-width: 2.6em;
        border-radius: 0px !important;
        text-align: center;
      }
    }

    button.widget-quantity-button {
      border: 1px solid #333;
      min-width: 25px;
      margin: 0;
      &.widget-quantity-button-dec {
        border-right-width: 0;
      }
      &.widget-quantity-button-inc {
        border-left-width: 0;
      }
    }

    .widget-compare-at-price {
      margin-right: 5px;
      position: relative;
    }

    .widget-compare-at-price:before {
      position: absolute;
      content: "";
      left: 0;
      top: 50%;
      right: 0;
      border-top: 2px solid;
      border-color: #000;
      opacity: 0.33;

      -webkit-transform:rotate(-8deg);
      -moz-transform:rotate(-8deg);
      -ms-transform:rotate(-8deg);
      -o-transform:rotate(-8deg);
      transform:rotate(-8deg);
    }

    .widget-product-properties {
      display: block;
      font-size: 12px;
      opacity: 0.8;
      line-height: 1;
      white-space: nowrap;
    }

    td.widget-product-button {
      width: 0px;
      white-space: nowrap;
      padding: 12px 0;
      button {
        width: calc(100% - 16px);
        display: block;
        line-height: 1.5;
        cursor: pointer;
        margin: 12px 8px;
      }
      button.unavailable {
        cursor: default;
      }
    }

    td.widget-add-to-cart-button-bottom {
      padding: 12px 8px;
      button {
        min-width: 250px;
        margin: 0 0 0 auto;
        display: block;
        line-height: 1.5;
        cursor: pointer;
      }

      @media screen and (max-width: 767px) {
        button {
          width: 100%;
        }
      }
    }

    td.empty {
      width: 100%;
    }

  }



</style>
