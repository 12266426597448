import Vue from "vue";
import vex from "vex-js";
import vexDialog from "vex-dialog";

// Main css
import "vex-js/dist/css/vex.css";

// Themes
import "vex-js/dist/css/vex-theme-default.css";

// Options
vex.defaultOptions.className = "vex-theme-default";

// Register vex-dialog
vex.registerPlugin(vexDialog);

Vue.prototype.$vex = vex;
