<template>
  <div
    ref="instabuyWidget"
    :class="['instabuy-widget', 'instabuy-widget-with-' + widget.navFormat]"
  >
    <component
      :is="'style'"
      v-if="widget.customCss > ''"
      v-html="widget.customCss"
    ></component>

    <AccessControl
      :widget="widget"
      :customer="customer"
      :customerMatchesAccessControlCustomerTags="
        customerMatchesAccessControlCustomerTags
      "
      v-if="!accessAllowed"
      @widget-authenticated="$emit('widget-authenticated', $event)"
    ></AccessControl>

    <template v-if="accessAllowed">
      <WidgetHeader
        :widget="widget"
        :panes="panes"
        :activePaneIndex="activePaneIndex"
        :isSearching="isSearching"
        :searchIsLoading="searchIsLoading"
        @active-pane-index-changed="setActivePaneIndex"
        @search-query-changed="setSearchQuery($event)"
      ></WidgetHeader>

      <WidgetPane
        v-for="(pane, index) in panes"
        v-show="!isSearching && (showAllPanes || activePaneIndex === index)"
        :visible="!isSearching && (showAllPanes || activePaneIndex === index)"
        :appSearchQuery="useShopifySearch ? null : searchQuery"
        :type="pane.type"
        :title="pane.title"
        :collection="pane.collection"
        :product="pane.product"
        :key="pane.id"
        :widget="widget"
        :stagedProducts="stagedProducts"
        :pricingRules="pricingRules"
        :customer="customer"
        :cart="cart"
        @add-to-cart="addToCart"
        @re-rendered="runAfterRenderCallbackOnNextTick"
        @scroll-to-widget-top="scrollToWidgetTop()"
      ></WidgetPane>

      <SearchResults
        v-if="isSearching"
        :searchQuery="searchQuery"
        :widget="widget"
        :stagedProducts="stagedProducts"
        @re-rendered="runAfterRenderCallbackOnNextTick"
        @search-is-loading="setSearchIsLoading($event)"
        @add-to-cart="addToCart"
      ></SearchResults>
    </template>
  </div>
</template>

<script>
import EQCSS from "eqcss";
import AccessControl from "./components/AccessControl.vue";
import WidgetHeader from "./components/WidgetHeader.vue";
import WidgetPane from "./components/WidgetPane.vue";
import SearchResults from "./components/SearchResults.vue";
import { EventBus } from "./EventBus.js";
import VueJsonp from "vue-jsonp";
import Vue from "vue";
import "./lib/vex-plugin";

Vue.use(VueJsonp);

export default {
  props: ["widget", "pricingRules", "customer"],

  components: {
    AccessControl,
    WidgetHeader,
    WidgetPane,
    SearchResults,
  },

  data() {
    return {
      collections: [],
      products: [],
      activePaneIndex: null,
      searchQuery: "",
      resolvedSearchQuery: "",
      searchIsLoading: false,
      stagedProducts: {},
      cart: {},
    };
  },

  computed: {
    panes() {
      var panes = [];
      if (this.widget.displayAllProducts === true) {
        var allProductsTitle =
          window.InstaBuy && window.InstaBuy.allProductsTitle
            ? window.InstaBuy.allProductsTitle
            : "All products";
        panes.push({
          type: "all_products",
          title: allProductsTitle,
          id: "all_products",
        });
      }
      panes = panes.concat(this.sortedCollectionPanes);
      panes = panes.concat(
        this.sortedProducts.map((product) => {
          return {
            type: "product",
            title: product.title,
            product,
            id: `product-${product.id}`,
          };
        })
      );
      return panes;
    },

    collectionPanes() {
      return this.collections.map((collection) => {
        return {
          type: "collection",
          title: collection.title
            ? collection.title.replace(/(<([^>]+)>)/gi, "")
            : null,
          handle: collection.handle,
          collection,
          id: `collection-${collection.id}`,
        };
      });
    },

    sortedCollectionPanes() {
      var _this = this;
      return this.collectionPanes.sort(function (a, b) {
        if (
          _this.widget.displayAllCollections === true ||
          !_this.widget.collectionHandles
        ) {
          return a.title - b.title;
        } else {
          return (
            _this.widget.collectionHandles.indexOf(a.handle) -
            _this.widget.collectionHandles.indexOf(b.handle)
          );
        }
      });
    },

    showAllPanes() {
      return this.widget.navFormat === "list";
    },

    isSearching() {
      return this.useShopifySearch && this.searchQuery.length > 0;
    },

    // TODO temp - move to widget option?
    useShopifySearch() {
      return !(window.InstaBuy && window.InstaBuy.useCustomSearch);
    },

    sortedProducts() {
      var productHandles = this.widget.productHandles.split(",");
      return this.products.concat().sort(function (a, b) {
        return (
          productHandles.indexOf(a.handle) - productHandles.indexOf(b.handle)
        );
      });
    },

    accessAllowed() {
      if (
        this.widget.accessControl === "password" &&
        this.widget.passwordRequired
      ) {
        return false;
      }
      if (
        this.widget.accessControl === "customers" &&
        (!this.customer || !this.customer.id)
      ) {
        return false;
      }
      if (
        this.widget.accessControl === "customers_tagged" &&
        !this.customerMatchesAccessControlCustomerTags
      ) {
        return false;
      }
      return true;
    },

    customerMatchesAccessControlCustomerTags() {
      if (!this.customer || !this.customer.tags) {
        return false;
      }
      var accessControlTags = this.widget.accessControlCustomerTags;
      for (var accessControlTag of accessControlTags) {
        for (var customerTag of this.customer.tags) {
          if (
            customerTag.trim().toLowerCase() ===
            accessControlTag.trim().toLowerCase()
          ) {
            return true;
          }
        }
      }
      return false;
    },
  },

  mounted() {
    if (this.widget.passwordRequired) {
      return;
    }
    if (this.widget.customPricing) {
      EventBus.$emit("show-instabuy-cart");
    }
    if (this.widget.showCart !== false && !this.widget.customPricing) {
      window.InstaBuy.shopifyCartVisible = true;
      EventBus.$emit("show-shopify-cart");
    }
    // Populate data
    if (this.widget.displayAllCollections === true) {
      this.fetchCollectionsPage(1);
    }
    if (this.widget.collectionHandles.length > 0) {
      var collectionPromises = [];
      var collectionHandles = this.widget.collectionHandles.split(",");
      for (var i = 0; i < collectionHandles.length; i++) {
        var collectionPromise = fetch(
          `${this.widget.proxiedShopUrl}/collections/${collectionHandles[i]}.json`
        )
          .then((response) => response.json())
          .then((data) => {
            this.collections = this.collections.concat(data.collection);
          })
          .catch((error) => {});
        collectionPromises.push(collectionPromise);
      }
      Promise.all(collectionPromises)
        .then(this.initializeActivePaneIndex)
        .catch(this.initializeActivePaneIndex);
    }
    if (this.widget.productHandles.length > 0) {
      var productPromises = [];
      var productHandles = this.widget.productHandles.split(",");
      for (var i = 0; i < productHandles.length; i++) {
        var productPromise = fetch(
          `${this.widget.proxiedShopUrl}/products/${productHandles[i]}?view=instabuy-data`
        )
          .then((response) => response.json())
          .then((data) => {
            this.products = this.products.concat(data.product);
          })
          .catch((error) => {});
        productPromises.push(productPromise);
      }
      Promise.all(productPromises)
        .then(this.initializeActivePaneIndex)
        .catch(this.initializeActivePaneIndex);
    }
    this.initializeActivePaneIndex();
  },

  created() {
    this.$root.$on("quantity-change", this.quantityChange);
    this.$root.$on("clear-quantity", this.clearStagedProducts);
    EventBus.$on("cart-data", (cart) => {
      this.cart = cart;
    });
  },

  beforeDestroy() {
    this.$root.$off("quantity-change", this.quantityChange);
    this.$root.$off("clear-quantity", this.clearStagedProducts);
  },

  updated() {
    this.$nextTick(function () {
      if (EQCSS) EQCSS.apply();
    });
  },

  methods: {
    reloadWidget() {},

    quantityChange(event) {
      this.$set(this.stagedProducts, event.variantId, event.quantity);
    },

    clearStagedProducts() {
      this.stagedProducts = {};
    },

    fetchCollectionsPage(page) {
      fetch(`${this.widget.proxiedShopUrl}/collections.json?page=${page}`)
        .then((response) => response.json())
        .then((data) => {
          this.collections = this.collections.concat(data.collections);
          if (data.collections.length === 30) {
            this.fetchCollectionsPage(page + 1);
          }
          this.initializeActivePaneIndex();
        });
    },

    runAfterRenderCallbackOnNextTick() {
      if (window.InstaBuy.afterRender) {
        this.$nextTick(this.runAfterRenderCallback);
      }
    },

    runAfterRenderCallback() {
      if (window.InstaBuy.afterRender) {
        window.InstaBuy.afterRender.call(this, this.$el);
      }
      if (EQCSS) EQCSS.apply();
    },

    addToCart(data) {
      if (this.widget.customPricing) {
        EventBus.$emit("instabuy-add-to-cart", data);
      } else {
        EventBus.$emit("shopify-add-to-cart", data);
      }
    },

    initializeActivePaneIndex() {
      // Set current pane
      if (this.widget.defaultPane) {
        for (var i = this.panes.length - 1; i >= 0; i--) {
          if (
            this.widget.defaultPane === "collection" &&
            this.widget.displayAllCollections &&
            this.panes[i].type === "collection"
          ) {
            this.activePaneIndex = i;
          }
          if (
            this.widget.defaultPane === "all_products" &&
            this.widget.displayAllProducts &&
            this.panes[i].type === "all_products"
          ) {
            this.activePaneIndex = i;
          }
          if (this.widget.defaultPane === this.panes[i].id) {
            this.activePaneIndex = i;
          }
        }
      }
      this.activePaneIndex = this.activePaneIndex || 0;
      this.runAfterRenderCallbackOnNextTick();
    },

    setActivePaneIndex(index) {
      this.activePaneIndex = index;
    },

    setSearchQuery(newSearchQuery) {
      console.log(newSearchQuery);
      this.searchQuery = newSearchQuery;
    },

    setSearchIsLoading(value) {
      this.searchIsLoading = this.isSearching ? value : false;
    },

    scrollToWidgetTop() {
      this.scrollTo(
        this.$refs.instabuyWidget.getBoundingClientRect().top +
          document.documentElement.scrollTop,
        150
      );
    },

    /* From https://stackoverflow.com/a/39494245 */
    scrollTo(elementY, duration) {
      var startingY = window.pageYOffset;
      var diff = elementY - startingY;
      var start;

      // Bootstrap our animation - it will get called right before next frame shall be rendered.
      window.requestAnimationFrame(function step(timestamp) {
        if (!start) start = timestamp;
        // Elapsed milliseconds since start of scrolling.
        var time = timestamp - start;
        // Get percent of completion in range [0, 1].
        var percent = Math.min(time / duration, 1);

        window.scrollTo(0, startingY + diff * percent);

        // Proceed with animation as long as we wanted it to.
        if (time < duration) {
          window.requestAnimationFrame(step);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.instabuy-widget {
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;

  td {
    display: table-cell;
  }

  * {
    box-sizing: border-box;
  }

  &.instabuy-widget-with-list {
    .all-products-pane,
    .collection-pane {
      margin-bottom: 50px;
    }
  }

  a,
  a:not(.btn),
  a:not(button) {
    text-decoration: none;
    border-bottom: 0px;
    padding: 0px;
  }

  button {
    border: none;
    outline: none;
    transition: box-shadow 0.1s;
    height: auto;
    span {
      transition: top 0.2s, left 0.2s;
    }
  }

  button.widget-add-to-cart,
  button.widget-show-variants,
  button.widget-show-search-input,
  .widget-pagination button {
    &.active,
    &.error,
    &:active,
    &.adding {
      box-shadow: inset 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
      span {
        position: relative;
        top: 1px;
        left: 1px;
      }
    }
  }

  button.widget-add-to-cart {
    overflow: hidden;
    position: relative;
    backface-visibility: hidden;

    &.active span,
    &.adding span {
      transform: translateY(300%);
      transition: transform 0.25s ease-in-out;
    }

    &:disabled {
      pointer-events: none;
    }

    span {
      display: inline-block;
      width: 100%;
      height: 100%;
      transition: transform 0.2s ease-in-out;
      backface-visibility: hidden;
    }

    .widget-add-to-cart-success {
      left: 0;
      top: -100%;
      position: absolute;
      height: 100%;
      width: 100%;
      transition: top 0.2s ease-in-out;
      svg {
        width: 20px;
        height: 20px;
        top: 35%;
        position: relative;
        fill: #fff;
        margin-top: 0px;
        top: calc(50% - 10px);
        display: inline-block;
      }
    }

    &.active .widget-add-to-cart-success {
      top: 0;
      transition: top 0.25s ease-in-out 0s;
    }

    .widget-adding-to-cart {
      left: 0;
      top: 100%;
      position: absolute;
      height: 100%;
      width: 100%;
      transition: top 0.2s ease-in-out;
      svg {
        width: 20px;
        height: 20px;
        top: 35%;
        position: relative;
        fill: #fff;
        margin-top: 0px;
        top: calc(50% - 10px);
        display: inline-block;
      }
    }

    &.adding .widget-adding-to-cart {
      top: 0;
      transition: top 0.25s ease-in-out 0s;
    }
  }

  em.widget-highlight {
    background-color: #f2eed9;
    padding: 3px 5px;
    margin: -3px -5px;
    display: inline-block;
    font-style: normal;
    border-radius: 4px;
  }
}

tr.widget-product-mobile {
  display: none;
}

@element .instabuy-widget and (max-width: 600px) {
  table.instabuy-product-table td.widget-product-image:not(:empty) {
    min-width: 62px;
  }
  table.instabuy-product-table td.widget-product-image img {
    max-width: 50px;
    max-height: 50px;
    margin: 0;
  }

  tr.widget-product-desktop {
    display: none;
  }

  tr.widget-product-mobile {
    display: table-row;
  }

  table.instabuy-product-table tr.widget-product-mobile-second {
    display: table-row;
    td {
      border-top: none;
      padding-top: 0;
    }
  }

  tr.widget-product-main {
    td {
      padding-bottom: 6px;
    }
    td.widget-product-title h4 {
      word-break: break-word;
    }
    td.widget-quantity,
    td.widget-product-sku,
    td.widget-product-button {
      display: none;
    }
    td.widget-product-price {
      text-align: center;

      .widget-product-properties {
        display: none;
      }
    }
  }
}

.widget-spinner {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjAiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAxMjggMTI4IiB4bWw6c3BhY2U9InByZXNlcnZlIj48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSIjRkZGRkZGIiAvPjxnPjxwYXRoIGQ9Ik03NS40IDEyNi42M2ExMS40MyAxMS40MyAwIDAgMS0yLjEtMjIuNjUgNDAuOSA0MC45IDAgMCAwIDMwLjUtMzAuNiAxMS40IDExLjQgMCAxIDEgMjIuMjcgNC44N2guMDJhNjMuNzcgNjMuNzcgMCAwIDEtNDcuOCA0OC4wNXYtLjAyYTExLjM4IDExLjM4IDAgMCAxLTIuOTMuMzd6IiBmaWxsPSIjMzMzMzMzIiBmaWxsLW9wYWNpdHk9IjEiLz48YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InJvdGF0ZSIgZnJvbT0iMCA2NCA2NCIgdG89IjM2MCA2NCA2NCIgZHVyPSI2MDBtcyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiPjwvYW5pbWF0ZVRyYW5zZm9ybT48L2c+PC9zdmc+) !important;
  background-repeat: no-repeat;
  background-position: center center;
}

.widget-products-spinner {
  width: 100%;
  margin: 30px 20px;
  .widget-spinner {
    width: 50px;
    height: 50px;
    background-size: 50px 50px;
    margin: 0 auto;
  }
}

.widget-pane-product {
  hr.instabuy-hr {
    border-top-width: 0px;
  }
}

.widget-pane-product:last-child {
  hr.instabuy-hr {
    border-top-width: 2px;
  }
}

.lum-lightbox {
  z-index: 10000;
}
</style>
