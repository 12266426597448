<template>
  <div>
    <h2 class="widget-title" v-html="widget.title"></h2>
    <template
      v-if="widget.accessControl === 'password' && widget.passwordRequired"
    >
      <p
        class="instabuy-access-control-content"
        v-html="widget.passwordRequiredContent"
      ></p>
      <input type="password" v-model="password" class="instabuy-password" />
      <button
        type="button"
        @click="authenticate"
        class="instabuy-password-submit"
      >
        {{ widget.passwordButtonText }}
      </button>
    </template>

    <template
      v-if="widget.accessControl === 'customers' && (!customer || !customer.id)"
    >
      <p
        class="instabuy-access-control-content"
        v-html="widget.customerRequiredContent"
      ></p>
    </template>

    <template v-if="widget.accessControl === 'customers_tagged'">
      <p
        class="instabuy-access-control-content"
        v-if="!customer || !customer.id"
        v-html="widget.customerRequiredContent"
      ></p>
      <p
        class="instabuy-access-control-content"
        v-if="
          customer && customer.id && !customerMatchesAccessControlCustomerTags
        "
        v-html="widget.taggedCustomerRequiredContent"
      ></p>
    </template>
  </div>
</template>

<script>
const authApi = {
  development: `https://instabuy.test/`,
  production: `https://api.useinstabuy.com/`,
}[process.env.NODE_ENV];

export default {
  props: ["widget", "customer", "customerMatchesAccessControlCustomerTags"],

  data() {
    return {
      password: null,
    };
  },

  computed: {
    accessControl() {
      return this.widget.accessControl === "password";
    },
  },

  methods: {
    authenticate() {
      fetch(`${authApi}auth`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          widget_id: this.widget.id,
          password: this.password,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            window.alert("Invalid password");
            this.password = null;
          }
        })
        .then((authData) => {
          if (!authData) return;
          this.authenticated(authData);
        });
    },

    authenticated(authData) {
      this.$emit("widget-authenticated", authData);
    },
  },
};
</script>

<style scoped>
h2.widget-title {
  margin-top: 10px;
  margin-bottom: 10px;
  flex-grow: 3;
  font-size: 26px;
  text-transform: none;
  letter-spacing: 0;
  font-weight: 600;
  text-align: left;
}
.instabuy-password {
  padding: 8px 12px;
  font-size: 16px;
  height: 40px;
  border: 1px solid #999;
  border-right: none;
}
.instabuy-password-submit {
  background: #007a5c;
  color: white;
  padding: 8px 12px;
  font-size: 16px;
  height: 40px;
}
p.instabuy-access-control-content {
  font-size: 16px;
  margin-bottom: 20px;
}
p.instabuy-access-control-content >>> a,
p.instabuy-access-control-content >>> a:not(.btn),
p.instabuy-access-control-content >>> a:not(button) {
  text-decoration: underline;
}
</style>
