<template>
  <div class="all-products-pane">
    <ProductTable
      :stagedProducts="stagedProducts"
      :widget="widget"
      :productCount="
        fetchedPages[currentPage] && fetchedPages[currentPage].length
      "
    >
      <Product
        v-for="product in filteredProducts"
        :product="product"
        :key="product.id"
        :widget="widget"
        :stagedProducts="stagedProducts"
        :pricingRules="pricingRules"
        :customer="customer"
        :highlightSearchQuery="highlightSearchQuery"
        :cart="cart"
        @add-to-cart="$emit('add-to-cart', $event)"
        @re-rendered="$emit('re-rendered', $event)"
      ></Product>
    </ProductTable>
    <div v-if="isLoading" class="widget-products-spinner">
      <div class="widget-spinner"></div>
    </div>
    <Pagination
      v-if="pageCount > autoloadPageThreshold"
      :widget="widget"
      :pageCount="pageCount"
      :isLoading="isLoading"
      :currentPage="currentPage"
      @load-page="loadPage($event)"
    ></Pagination>
  </div>
</template>

<script>
import Product from "./Product.vue";
import ProductTable from "./ProductTable.vue";
import Pagination from "./Pagination.vue";

export default {
  props: [
    "widget",
    "stagedProducts",
    "pricingRules",
    "customer",
    "highlightSearchQuery",
    "cart",
  ],

  components: {
    Product,
    ProductTable,
    Pagination,
  },

  data() {
    return {
      products: [],
      fetchedPages: [],
      pageCount: null,
      productsCount: null,
      isLoading: true,
      currentPage: 1,
      autoloadPageThreshold: 1,
    };
  },

  mounted() {
    this.fetchPage(1);
  },

  computed: {
    filteredProducts() {
      if (
        window.InstaBuy &&
        (typeof window.InstaBuy.filterVariant === "function" ||
          typeof window.InstaBuy.filterProduct === "function")
      ) {
        var filteredProducts = [];
        var products = this.fetchedPages[this.currentPage];
        if (typeof products === "undefined") {
          return [];
        }
        for (var i = 0; i < products.length; i++) {
          var product = products[i];
          var filteredVariants = [];
          for (var j = 0; j < product.variants.length; j++) {
            if (
              typeof window.InstaBuy.filterVariant !== "function" ||
              window.InstaBuy.filterVariant(
                product.variants[j],
                this.highlightSearchQuery
              ) !== false
            ) {
              filteredVariants.push(product.variants[j]);
            }
          }
          if (filteredVariants.length > 0) {
            product.variants = filteredVariants;
            if (typeof window.InstaBuy.filterProduct === "function") {
              if (
                window.InstaBuy.filterProduct(
                  product,
                  this.highlightSearchQuery
                )
              ) {
                filteredProducts.push(product);
              }
            } else {
              filteredProducts.push(product);
            }
          }
        }
      } else {
        var filteredProducts = this.fetchedPages[this.currentPage];
      }
      return filteredProducts;
    },
  },

  methods: {
    fetchPage(page, callback) {
      this.isLoading = true;
      fetch(
        `${this.widget.proxiedShopUrl}/collections/all?page=${page}&view=instabuy-data`,
        { credentials: "include" }
      )
        .then((response) => response.json())
        .then((data) => {
          if (window.InstaBuy && window.InstaBuy.debug) {
          }
          this.pageCount = data.pages;
          this.productsCount = data.items;
          if (this.pageCount <= this.autoloadPageThreshold) {
            this.$set(
              this.fetchedPages,
              1,
              (this.fetchedPages[1] || []).concat(data.products)
            );
            if (page < data.pages) {
              this.fetchPage(page + 1);
            } else {
              this.isLoading = false;
            }
          } else {
            this.$set(this.fetchedPages, page, data.products);
            this.isLoading = false;
            if (callback) {
              callback.bind(this).call();
            }
          }
        });
    },

    loadPage(page) {
      this.currentPage = page;
      if (!this.fetchedPages[page]) {
        this.fetchPage(page);
      }
      this.$emit("scroll-to-widget-top");
    },
  },
};
</script>

<style scoped>
</style>
