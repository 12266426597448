<template>
  <td class="widget-product-button" :colspan="colspan">
    <button
      :class="['widget-show-variants', variantsExpanded ? 'active' : '']"
      v-if="hasMultipleVariants && (widget.expandVariants === false || widget.expandVariants === false) && !forceVariantsExpanded"
      :style="showVariantsButtonStyle"
      v-on:click="$emit('toggle-expand-variants')"><span>{{widget.showVariantsButtonText}}</span></button>
    <button
      class="widget-unavailable"
      disabled=true
      v-if="!hasMultipleVariants && !variant.available"
      :style="unavailableButtonStyle"><span>{{widget.unavailableButtonText}}</span></button>
    <button
      :class="['widget-add-to-cart', addToCartIsActive ? 'active' : '', addToCartIsError ? 'error' : '']"
      v-if="widget.buttonPosition === 'row' && !hasMultipleVariants && variant.available"
      :style="addToCartButtonStyle"
      v-on:click="addToCart">
      <div class="widget-add-to-cart-success">
        <svg :style="addToCartButtonIconStyle" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
           viewBox="0 0 490.05 490.05" style="enable-background:new 0 0 490.05 490.05;" xml:space="preserve">
        <g>
          <g>
            <path d="M418.275,418.275c95.7-95.7,95.7-250.8,0-346.5s-250.8-95.7-346.5,0s-95.7,250.8,0,346.5S322.675,513.975,418.275,418.275
              z M157.175,207.575l55.1,55.1l120.7-120.6l42.7,42.7l-120.6,120.6l-42.8,42.7l-42.7-42.7l-55.1-55.1L157.175,207.575z"/>
          </g>
        </g>
        </svg>
      </div>
      <span>{{widget.addToCartButtonText}}</span>
    </button>
  </td>
</template>

<script>
export default {
  props: ['variantsExpanded', 'hasMultipleVariants', 'forceVariantsExpanded', 'product', 'variant', 'widget', 'quantity', 'colspan'],

  data: function() {
    return {
      addToCartIsActive: false,
      addToCartIsError: false,
    };
  },

  computed: {
    addToCartButtonStyle() {
      return `background-color: ${this.widget.addToCartButtonBackgroundColor};
      color: ${this.widget.addToCartButtonTextColor};
      border-radius: ${this.widget.buttonBorderRadius};
      padding: ${this.widget.buttonPadding};
      font-size: ${this.widget.buttonFontSize};
      border: 1px solid ${this.widget.addToCartButtonBorderColor};`;
    },

    addToCartButtonIconStyle() {
      return `fill: ${this.widget.addToCartButtonTextColor};`;
    },

    unavailableButtonStyle() {
      return `background-color: #EEE;
      color: #333;
      border-radius: ${this.widget.buttonBorderRadius};
      padding: ${this.widget.buttonPadding};
      font-size: ${this.widget.buttonFontSize};
      border: 1px solid #EEE;`;
    },

    showVariantsButtonStyle() {
      return `background-color: ${this.widget.showVariantsButtonBackgroundColor};
      color: ${this.widget.showVariantsButtonTextColor};
      border-radius: ${this.widget.buttonBorderRadius};
      padding: ${this.widget.buttonPadding};
      font-size: ${this.widget.buttonFontSize};
      border: 1px solid ${this.widget.showVariantsButtonBorderColor};`;
    }
  },

  methods: {
    setAddToCartActive() {
      var _this = this;
      this.addToCartIsActive = true;
      setTimeout(function() { _this.addToCartIsActive = false; }, 1200);
    },

    setAddToCartError() {
      var _this = this;
      this.addToCartIsError = true;
      setTimeout(function() { _this.addToCartIsError = false; }, 1200);
    },

    addToCart() {
      if(this.quantity > 0) { 
        this.setAddToCartActive(); 
        this.$emit('add-to-cart', {product: this.product, variantId: this.variant.id, quantity: this.quantity, errorCallback: this.setAddToCartError}) 
      }
    }
  }
}
</script>
