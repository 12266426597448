<template>
  <div :class="['widget-pane', 'widget-pane-' + type]">
    <AllProductsPane
      v-if="type === 'all_products'"
      :widget="widget"
      :visible="visible"
      :stagedProducts="stagedProducts"
      :pricingRules="pricingRules"
      :customer="customer"
      :highlightSearchQuery="appSearchQuery"
      :cart="cart"
      @add-to-cart="$emit('add-to-cart', $event)"
      @re-rendered="$emit('re-rendered', $event)"
      @scroll-to-widget-top="$emit('scroll-to-widget-top')"
    />
    <CollectionPane
      v-if="type === 'collection'"
      :title="title"
      :collection="collection"
      :widget="widget"
      :visible="visible"
      :stagedProducts="stagedProducts"
      :pricingRules="pricingRules"
      :customer="customer"
      :highlightSearchQuery="appSearchQuery"
      :cart="cart"
      @add-to-cart="$emit('add-to-cart', $event)"
      @re-rendered="$emit('re-rendered', $event)"
    />
    <ProductPane
      v-if="type === 'product'"
      :title="title"
      :product="product"
      :widget="widget"
      :visible="visible"
      :stagedProducts="stagedProducts"
      :pricingRules="pricingRules"
      :customer="customer"
      :highlightSearchQuery="appSearchQuery"
      :cart="cart"
      @add-to-cart="$emit('add-to-cart', $event)"
      @re-rendered="$emit('re-rendered', $event)"
    />
  </div>
</template>

<script>
import AllProductsPane from "./AllProductsPane.vue";
import CollectionPane from "./CollectionPane.vue";
import ProductPane from "./ProductPane.vue";

export default {
  props: [
    "type",
    "title",
    "collection",
    "product",
    "widget",
    "visible",
    "stagedProducts",
    "pricingRules",
    "customer",
    "appSearchQuery",
    "cart",
  ],
  components: {
    AllProductsPane,
    CollectionPane,
    ProductPane,
  },
};
</script>

<style scoped>
</style>
