<template>
  <a
    v-if="cartVisible"
    :href="shopUrl + '/cart'"
    :target="target"
    class="instabuy-cart"
    ref="instabuy-cart"
  >
    <div class="instabuy-cart-loading" v-if="isLoading"></div>
    <div class="instabuy-cart-content">
      <img
        class=""
        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA0ODMuMSA0ODMuMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDgzLjEgNDgzLjE7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo8Zz4KCTxwYXRoIGQ9Ik00MzQuNTUsNDE4LjdsLTI3LjgtMzEzLjNjLTAuNS02LjItNS43LTEwLjktMTItMTAuOWgtNTguNmMtMC4xLTUyLjEtNDIuNS05NC41LTk0LjYtOTQuNXMtOTQuNSw0Mi40LTk0LjYsOTQuNWgtNTguNiAgIGMtNi4yLDAtMTEuNCw0LjctMTIsMTAuOWwtMjcuOCwzMTMuM2MwLDAuNCwwLDAuNywwLDEuMWMwLDM0LjksMzIuMSw2My4zLDcxLjUsNjMuM2gyNDNjMzkuNCwwLDcxLjUtMjguNCw3MS41LTYzLjMgICBDNDM0LjU1LDQxOS40LDQzNC41NSw0MTkuMSw0MzQuNTUsNDE4Ljd6IE0yNDEuNTUsMjRjMzguOSwwLDcwLjUsMzEuNiw3MC42LDcwLjVoLTE0MS4yQzE3MS4wNSw1NS42LDIwMi42NSwyNCwyNDEuNTUsMjR6ICAgIE0zNjMuMDUsNDU5aC0yNDNjLTI2LDAtNDcuMi0xNy4zLTQ3LjUtMzguOGwyNi44LTMwMS43aDQ3LjZ2NDIuMWMwLDYuNiw1LjQsMTIsMTIsMTJzMTItNS40LDEyLTEydi00Mi4xaDE0MS4ydjQyLjEgICBjMCw2LjYsNS40LDEyLDEyLDEyczEyLTUuNCwxMi0xMnYtNDIuMWg0Ny42bDI2LjgsMzAxLjhDNDEwLjI1LDQ0MS43LDM4OS4wNSw0NTksMzYzLjA1LDQ1OXoiIGZpbGw9IiMzMzMzMzMiLz4KPC9nPgo8L3N2Zz4K"
        width="20"
        height="20"
      />
      <div :style="itemCountStyle" class="instabuy-cart-item-count">
        {{ itemCount }}
      </div>
      <div class="instabuy-cart-total-price" v-html="formattedTotalPrice"></div>
    </div>
  </a>
</template>

<script>
import { EventBus } from "./EventBus.js";
import formatMoney from "./lib/format-money.js";

export default {
  props: [
    "shopUrl",
    "moneyFormat",
    "buttonBackgroundColor",
    "buttonTextColor",
    "buttonBorderColor",
  ],

  data() {
    return {
      itemCount: null,
      totalPrice: null,
      cartFormattedTotalPrice: null,
      isLoading: true,
      cart: {},
      cartVisible: window.InstaBuy.shopifyCartVisible || false,
    };
  },

  components: {},

  created() {
    this.fetchCart();
  },

  mounted() {
    EventBus.$on("show-shopify-cart", this.showShopifyCart);
    EventBus.$on("shopify-add-to-cart", this.addToCartOne);
    EventBus.$on("shopify-add-to-cart-all", this.addToCartArray);
  },

  destroyed() {
    EventBus.$off("show-shopify-cart", this.showShopifyCart);
    EventBus.$off("shopify-add-to-cart", this.addToCartOne);
    EventBus.$off("shopify-add-to-cart-all", this.addToCartArray);
    this.$el.remove();
  },

  computed: {
    target() {
      if (window.InstaBuy && window.InstaBuy.target) {
        return window.InstaBuy.target;
      } else {
        return "_blank";
      }
    },

    itemCountStyle() {
      return `border: 1px solid ${this.buttonBorderColor};
              background-color: ${this.buttonBackgroundColor};
              color: ${this.buttonTextColor};`;
    },

    formattedTotalPrice() {
      if (this.totalPrice === null) {
        return "";
      }
      return typeof this.cartFormattedTotalPrice === "undefined"
        ? this.formatMoney(
            this.totalPrice,
            window.InstaBuy && window.InstaBuy.moneyFormat
              ? window.InstaBuy.moneyFormat
              : this.moneyFormat
          )
        : this.cartFormattedTotalPrice;
    },
  },

  watch: {
    cart: {
      handler(cart) {
        EventBus.$emit("cart-data", cart);
      },
      deep: true,
    },
  },

  methods: {
    showShopifyCart() {
      this.cartVisible = window.InstaBuy.shopifyCartVisible = true;
    },

    pushToCart({ variantId, quantity }) {
      if (window.InstaBuy && window.InstaBuy.beforeAddToCart) {
        window.InstaBuy.beforeAddToCart.call(this);
      }

      if (window.Shopify && window.Shopify.shop) {
        return this.$http.post(
          `/cart/add.js`,
          { id: +variantId, quantity },
          { headers: { "X-Requested-With": "XMLHttpRequest" } }
        );
      } else if (window.InstaBuy && window.InstaBuy.useXHRATC) {
        return this.$http.post(
          `${this.shopUrl}/cart/add.js`,
          { id: +variantId, quantity },
          { headers: { "X-Requested-With": "XMLHttpRequest" } }
        );
      } else {
        return this.$jsonp(
          `${this.shopUrl}/cart/add.json?id=${variantId}&quantity=${quantity}`
        );
      }
    },
    addToCartOne({ variantId, quantity, errorCallback }) {
      this.isLoading = true;
      this.pushToCart({ variantId, quantity })
        .then(() => {
          this.fetchCart().then(() => {
            if (window.InstaBuy && window.InstaBuy.afterAddToCart) {
              window.InstaBuy.afterAddToCart.call(this);
            } else {
              if (window.Shopify && window.Shopify.onItemAdded) {
                var lineItem =
                  this.cart &&
                  this.cart.items &&
                  this.cart.items.find((li) => li.variant_id === variantId);
                if (lineItem) {
                  Shopify.onItemAdded(lineItem);
                }
              }
            }

            // attempt to refresh theme mini cart
            document.documentElement.dispatchEvent(
              new CustomEvent("cart:refresh")
            );

            var cartCounts = document.querySelectorAll(
              "#CartCount,.cart-item-count,.CartCount,.cart_count"
            );
            if (window.InstaBuy && window.InstaBuy.cartCountSelector) {
              cartCounts = document.querySelectorAll(
                window.InstaBuy.cartCountSelector
              );
            }
            for (var i = 0; i < cartCounts.length; i++) {
              cartCounts[i].innerHTML = this.itemCount;
            }

            var cartTotals = document.querySelectorAll(".CartCost");
            if (window.InstaBuy && window.InstaBuy.cartTotalSelector) {
              cartTotals = document.querySelectorAll(
                window.InstaBuy.cartTotalSelector
              );
            }
            for (var j = 0; j < cartTotals.length; j++) {
              cartTotals[j].innerHTML = this.formattedTotalPrice;
            }

            if (this.$refs["instabuy-cart"]) {
              this.$refs["instabuy-cart"].classList.add(
                "instabuy-animated-cart"
              );
              var _this = this;
              setTimeout(function () {
                if (_this.$refs["instabuy-cart"]) {
                  _this.$refs["instabuy-cart"].classList.remove(
                    "instabuy-animated-cart"
                  );
                }
              }, 1100);
            }
          });
        })
        .catch((err) => {
          if (errorCallback) errorCallback.call(this, err);
          this.showAddToCartError(err);
          this.isLoading = false;
        });
    },
    addToCartArray(productsArray, clearAddingToCart) {
      if (productsArray.length > 0) {
        this.isLoading = true;

        if (this.$refs["instabuy-cart"]) {
          this.$refs["instabuy-cart"].classList.add("instabuy-animated-cart");
        }

        let promise = this.pushToCart(productsArray[0]);
        for (let i = 1; i < productsArray.length; i++) {
          promise = promise.then(() => this.pushToCart(productsArray[i]));
        }

        return promise
          .then(() => {
            if (clearAddingToCart) {
              clearAddingToCart();
            }
            this.fetchCart().then(() => {
              var _this = this;
              if (window.InstaBuy && window.InstaBuy.afterAddToCart) {
                window.InstaBuy.afterAddToCart.call(this);
              } else {
                if (window.Shopify && window.Shopify.onItemAdded) {
                  var lineItem =
                    this.cart &&
                    this.cart.items &&
                    productsArray[0] &&
                    productsArray[0].variantId;
                  this.cart.items.find(
                    (li) => li.variant_id === productsArray[0].variantId
                  );
                  if (lineItem) {
                    Shopify.onItemAdded(lineItem);
                  }
                }
              }

              // attempt to refresh theme mini cart
              document.documentElement.dispatchEvent(
                new CustomEvent("cart:refresh")
              );

              var cartCounts = document.querySelectorAll(
                "#CartCount,.cart-item-count"
              );
              if (window.InstaBuy && window.InstaBuy.cartCountSelector) {
                cartCounts = document.querySelectorAll(
                  window.InstaBuy.cartCountSelector
                );
              }
              for (var i = 0; i < cartCounts.length; i++) {
                cartCounts[i].innerHTML = this.itemCount;
              }

              if (this.$refs["instabuy-cart"]) {
                this.$refs["instabuy-cart"].classList.remove(
                  "instabuy-animated-cart"
                );
              }
            });
          })
          .catch((err) => {
            console.log(err);
            this.showAddToCartError(err);
            this.isLoading = false;
          });
      }
    },

    showAddToCartError(err) {
      if (
        window.InstaBuy &&
        typeof window.InstaBuy.addToCartErrorMessage === "function"
      ) {
        window.InstaBuy.addToCartErrorMessage.call(this);
      } else if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.description
      ) {
        window.alert(err.response.data.description);
      }
    },

    fetchCart() {
      this.isLoading = true;
      var cartCall;
      if (
        this.shopUrl === "https://store.rapiscan.com" ||
        this.shopUrl === "https://dev1.useinstabuy.com"
      ) {
        cartCall = this.$http
          .get(`${this.shopUrl || ""}/products?view=instabuy-cart`)
          .then((res) => res.data);
      } else {
        cartCall = this.$jsonp(`${this.shopUrl || ""}/cart.json`);
      }
      return cartCall.then((data) => {
        this.isLoading = false;
        this.itemCount = data.item_count;
        this.cart = data;
        this.totalPrice = data.total_price;
        this.cartFormattedTotalPrice = data.formatted_total_price;
      });
    },

    formatMoney,
  },
};
</script>

<style scoped>
.instabuy-cart {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 3px;
  border: 1px solid #333;
  padding: 10px 18px 10px 12px;
  background-color: #ffffff;
  z-index: 10000;
  white-space: nowrap;
  cursor: pointer;
  opacity: 0.95;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  transition: opacity 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.instabuy-cart:hover {
  opacity: 1;
  box-shadow: 0px 2px 15px -4px rgba(0, 0, 0, 0.3);
}

.instabuy-cart-icon {
  vertical-align: middle;
}

.instabuy-cart-item-count {
  border-radius: 25px;
  font-size: 12px;
  padding: 1px 4px;
  font-weight: bold;
  display: inline-block;
  margin-left: -6px;
  line-height: 1.2;
}

.instabuy-cart-total-price {
  display: inline-block;
  margin-left: 5px;
}

.instabuy-cart-loading {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjAiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAxMjggMTI4IiB4bWw6c3BhY2U9InByZXNlcnZlIj48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSIjRkZGRkZGIiAvPjxnPjxwYXRoIGQ9Ik03NS40IDEyNi42M2ExMS40MyAxMS40MyAwIDAgMS0yLjEtMjIuNjUgNDAuOSA0MC45IDAgMCAwIDMwLjUtMzAuNiAxMS40IDExLjQgMCAxIDEgMjIuMjcgNC44N2guMDJhNjMuNzcgNjMuNzcgMCAwIDEtNDcuOCA0OC4wNXYtLjAyYTExLjM4IDExLjM4IDAgMCAxLTIuOTMuMzd6IiBmaWxsPSIjMzMzMzMzIiBmaWxsLW9wYWNpdHk9IjEiLz48YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InJvdGF0ZSIgZnJvbT0iMCA2NCA2NCIgdG89IjM2MCA2NCA2NCIgZHVyPSI2MDBtcyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiPjwvYW5pbWF0ZVRyYW5zZm9ybT48L2c+PC9zdmc+) !important;
  background-size: 20px 20px;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.instabuy-animated-cart {
  animation: animation 1100ms linear both;
}

/* Generated with Bounce.js. Edit at https://goo.gl/YQHStw */

@keyframes animation {
  0% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4.28% {
    transform: matrix3d(1.112, 0, 0, 0, 0, 1.112, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  8.55% {
    transform: matrix3d(1.221, 0, 0, 0, 0, 1.221, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  8.99% {
    transform: matrix3d(1.23, 0, 0, 0, 0, 1.23, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  9.09% {
    transform: matrix3d(1.232, 0, 0, 0, 0, 1.232, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  12.83% {
    transform: matrix3d(1.185, 0, 0, 0, 0, 1.185, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  13.37% {
    transform: matrix3d(1.174, 0, 0, 0, 0, 1.174, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  17.02% {
    transform: matrix3d(1.087, 0, 0, 0, 0, 1.087, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  17.64% {
    transform: matrix3d(1.073, 0, 0, 0, 0, 1.073, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  21.92% {
    transform: matrix3d(0.995, 0, 0, 0, 0, 0.995, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  22.11% {
    transform: matrix3d(0.992, 0, 0, 0, 0, 0.992, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  26.11% {
    transform: matrix3d(0.96, 0, 0, 0, 0, 0.96, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  27.21% {
    transform: matrix3d(0.957, 0, 0, 0, 0, 0.957, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  31.2% {
    transform: matrix3d(0.96, 0, 0, 0, 0, 0.96, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  32.31% {
    transform: matrix3d(0.964, 0, 0, 0, 0, 0.964, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  36.3% {
    transform: matrix3d(0.98, 0, 0, 0, 0, 0.98, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  37.31% {
    transform: matrix3d(0.985, 0, 0, 0, 0, 0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  41.4% {
    transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  46.4% {
    transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  47.41% {
    transform: matrix3d(1.008, 0, 0, 0, 0, 1.008, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  56.5% {
    transform: matrix3d(1.004, 0, 0, 0, 0, 1.004, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  57.51% {
    transform: matrix3d(1.003, 0, 0, 0, 0, 1.003, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  66.6% {
    transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  77.71% {
    transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  86.8% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  90.91% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
</style>
