<template>
  <td class="widget-quantity" v-if="showQuantityCell">
    <div
      class="widget-quantity-input-wrapper"
      v-if="showQuantityInput === true && !hasMultipleVariants && variant.available">
      <button 
        class="widget-quantity-button widget-quantity-button-dec"
        @click="decrement"
        :style="decButtonStyle"
        v-if="showQuantityButtons === true">-</button>
      <input
        :class="showQuantityButtons === true ? 'widget-quantity-input widget-quantity-input-with-buttons' : 'widget-quantity-input'"
        type="number"
        :style="quantityInputStyle"
        :value="value"
        ref="qtyInput"
        :min="min"
        :step="step"
        :max="max"
        :data-variant-id="variant ? variant.id : ''"
        :data-product-handle="product ? product.handle : ''"
        :data-product-tags="product ? product.tags : ''"
        @OFFchange="$root.$emit('quantity-change', { variantId: variant.id, quantity: $event.target.value })"
        @change="guardMinOnChange($event.target.value)"
        @input="updateValue($event.target.value)" />
      <button 
        class="widget-quantity-button widget-quantity-button-inc"
        @click="increment"
        :style="incButtonStyle"
        v-if="showQuantityButtons === true">+</button>
    </div>
  </td>

</template>

<script>


export default {
  props: ['value', 'hasMultipleVariants', 'quantityInputStyle', 'variant', 'product', 'widget', 'showQuantityButtons', 'showQuantityInput', 'buttonBorderRadius'],
  components: {
  },
  data() {
    return {
    }
  },

  computed: {

    // If we're on a 'Show variants' product and there are no add to cart buttons
    // on the row, skip this cell so the show variants button lines up
    showQuantityCell() {
      return this.widget.buttonPosition === "row" || !this.hasMultipleVariants || this.widget.expandVariants !== false || this.forceVariantsExpanded
    },

    decButtonStyle() {
      return `border-radius: ${this.buttonBorderRadius} 0 0 ${this.buttonBorderRadius};`
    },

    incButtonStyle() {
      return `border-radius: 0 ${this.buttonBorderRadius} ${this.buttonBorderRadius} 0;`
    },

    min() {
      if(this.widget.buttonPosition == "bottom") { return 0 }
      return this.calculatedMin
    },

    calculatedMin() {
      return this.attributeFromTags('min_qty')
    },

    max() {
      return this.attributeFromTags('max_qty')
    },

    step() {
      return this.attributeFromTags('increment')
    },
  },

  mounted() {
    // Only set value to min or step if the buttons are on each row, i.e.
    // if button is on bottom values should be zero
    if((this.min || this.step) && this.widget.buttonPosition == "row") { 
      this.$emit('input', this.min || this.step)
    }
  },

  methods: {

    updateValue(newValue) {
      this.$emit('input', newValue)
      this.$root.$emit('quantity-change', { variantId: this.variant.id, quantity: newValue })
    },

    guardMinOnChange(newValue) {
      if(this.calculatedMin && newValue > 0 && newValue < this.calculatedMin) {
        newValue = this.calculatedMin
        this.updateValue(newValue)
      }
    },

    attributeFromTags(tagPrefix) {
      if(window.InstaBuy && window.InstaBuy.attributeFromTags) {
        return window.InstaBuy.attributeFromTags.call(this.product, tagPrefix)
      } else if(this.product && this.product.tags) {
        for(var tag of this.product.tags) {
          var tagParts = tag.split(":")
          if(tagParts.length === 2 && tagParts[0] === tagPrefix) {
            return tagParts[1]
          }
        }
      }
      if(window.minMaxify && window.minMaxify.getLimitsFor) {
        var limits = window.minMaxify.getLimitsFor({handle: this.product.handle})
        if(limits) {
          if(tagPrefix === 'min_qty' && limits.min) { return limits.min }
          if(tagPrefix === 'max_qty' && limits.max) { return limits.max }
          if(tagPrefix === 'increment' && limits.multiple) { return limits.multiple }
        }
      }
      return null;
    },

    decrement() {
      this.delta(-1)
    },

    increment() {
      this.delta(1)
    },

    delta(direction) {
      var step = 1
      if(this.$refs.qtyInput.step) {
        step = this.$refs.qtyInput.step
      }
      var newValue = +this.value + (step * direction)
      var min = 0
      if(this.$refs.qtyInput.min) {
        min = this.$refs.qtyInput.min
      }
      newValue = Math.max(min, newValue)

      if(this.calculatedMin && newValue < this.calculatedMin) {
        newValue = direction === -1 ? 0 : this.calculatedMin
      }

      var max
      if(this.$refs.qtyInput.max) {
        max = this.$refs.qtyInput.max
        newValue = Math.min(max, newValue)      
      }
      this.updateValue(newValue)
    }
  }

}
</script>

<style scoped>

.widget-quantity-input-wrapper {
  width: min-content;
  margin-left: auto !important;
}

input.widget-quantity-input-with-buttons::-webkit-outer-spin-button,
input.widget-quantity-input-with-buttons::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input.widget-quantity-input-with-buttons {
    -moz-appearance:textfield; /* Firefox */
}
</style>
