<template>
  <tbody>
    <tr class="instabuy-table-line-item">
      <td class="instabuy-table-line-item-title">
        {{ line.product.title }}
        <div>
          <small>{{ line.variant.title }}</small>
        </div>
        <div
          class="instabuy-table-line-item-quantity-wrapper instabuy-table-line-item-mobile-quantity"
        >
          <button
            class="instabuy-table-quantity-button instabuy-table-quantity-button-dec"
            @click="decrement"
          >
            &ndash;
          </button>
          <input
            type="number"
            :class="{
              'instabuy-line-item-quantity': true,
              'instabuy-line-item-quantity-error':
                line.errors && line.errors.length > 0
            }"
            :value="line.quantity"
            :min="line.minQuantity"
            :max="line.maxQuantity"
            :step="line.incrementQuantity"
            @input="$emit('update-quantity', line, $event.target.value)"
          />
          <button
            class="instabuy-table-quantity-button instabuy-table-quantity-button-inc"
            @click="increment"
          >
            +
          </button>
        </div>
      </td>
      <td class="instabuy-table-line-item-quantity">
        <div class="instabuy-table-line-item-quantity-wrapper">
          <button
            class="instabuy-table-quantity-button instabuy-table-quantity-button-dec"
            @click="decrement"
          >
            -
          </button>
          <input
            type="number"
            :class="{
              'instabuy-line-item-quantity': true,
              'instabuy-line-item-quantity-error':
                line.errors && line.errors.length > 0
            }"
            :value="line.quantity"
            :min="line.minQuantity"
            :max="line.maxQuantity"
            :step="line.incrementQuantity"
            @input="$emit('update-quantity', line, $event.target.value)"
          />
          <button
            class="instabuy-table-quantity-button instabuy-table-quantity-button-inc"
            @click="increment"
          >
            +
          </button>
        </div>
      </td>
      <td
        class="instabuy-table-line-item-price"
        v-html="formatMoney(line.instabuyTotalPrice, moneyFormat)"
      ></td>
      <td class="instabuy-table-line-item-remove">
        <button
          class="instabuy-remove-line-item"
          @click="$emit('remove-line', line)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:svgjs="http://svgjs.com/svgjs"
            width="18"
            height="18"
            x="0"
            y="0"
            viewBox="0 0 512 512"
            style="enable-background: new 0 0 512 512"
            xml:space="preserve"
            class=""
          >
            <g>
              <path
                d="M442.154 145c10.585 0 17.924-10.701 13.955-20.514C442.016 89.645 407.834 65 368 65h-18.414C342.719 28.727 310.916 0 272 0h-32c-38.891 0-70.715 28.708-77.586 65H144c-39.834 0-74.016 24.645-88.109 59.486C51.922 134.299 59.261 145 69.846 145zM240 30h32c21.9 0 40.49 14.734 46.748 35H193.252C199.51 44.734 218.1 30 240 30zM111.053 470.196C112.722 493.638 132.439 512 155.939 512H356.06c23.5 0 43.217-18.362 44.886-41.804L421.969 175H90.031zm185.966-214.945c.414-8.274 7.469-14.655 15.73-14.232 8.274.414 14.646 7.457 14.232 15.73l-8 160c-.401 8.019-7.029 14.251-14.969 14.251-8.637 0-15.42-7.223-14.994-15.749zm-97.768-14.232c8.263-.415 15.317 5.959 15.73 14.232l8 160c.426 8.53-6.362 15.749-14.994 15.749-7.94 0-14.568-6.232-14.969-14.251l-8-160c-.413-8.273 5.959-15.316 14.233-15.73z"
                fill="#ffffff"
                data-original="#000000"
                class=""
              ></path>
            </g>
          </svg>
        </button>
      </td>
    </tr>
    <tr
      colspan="4"
      v-if="line.errors && line.errors.length > 0"
      class="instabuy-table-line-item-error-row"
    >
      <td colspan="4">
        <div v-html="errorText" class="instabuy-line-item-error"></div>
      </td>
    </tr>
  </tbody>
</template>

<script>
import { EventBus } from "../EventBus.js";
import formatMoney from "@/lib/format-money.js";

export default {
  props: ["cart", "line", "moneyFormat"],

  computed: {
    errorText() {
      let errorTexts = [];
      for (var errorType of this.line.errors) {
        if (errorType === "min") {
          errorTexts.push(`The minimum quantity is ${this.line.minQuantity}`);
        }
        if (errorType === "max") {
          errorTexts.push(`The maximum quantity is ${this.line.maxQuantity}`);
        }
        if (errorType === "increment") {
          errorTexts.push(
            `The product can only be ordered in multiples of ${this.line.incrementQuantity}`
          );
        }
        if (errorType === "available") {
          errorTexts.push(
            `Only ${this.line.variant.inventory_quantity} are available`
          );
        }
      }
      return errorTexts.join("<br />");
    }
  },

  methods: {
    formatMoney,

    decrement() {
      this.delta(-1);
    },

    increment() {
      this.delta(1);
    },

    delta(direction) {
      var step = 1;
      if (this.line.incrementQuantity) {
        step = this.line.incrementQuantity;
      }
      var newValue = this.line.quantity + step * direction;
      if (step > 1 && newValue % step !== 0) {
        newValue =
          direction === 1
            ? Math.ceil(this.line.quantity / step) * step
            : Math.floor(this.line.quantity / step) * step;
      }
      var min = 0;
      if (this.line.minQuantity) {
        min = this.line.minQuantity;
      }
      newValue = Math.max(min, newValue);

      if (this.line.maxQuantity) {
        var max;
        max = this.line.maxQuantity;
        newValue = Math.min(max, newValue);
      }
      this.$emit("update-quantity", this.line, newValue);
    }
  }
};
</script>

<style scoped></style>
