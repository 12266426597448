<template>
  <div :class="{ 'instabuy-order': true, expanded }" v-if="cartVisible">
    <vue-final-modal
      name="atc-notes-modal"
      v-model="isShowingNotes"
      @opened="$refs.notesTextarea && $refs.notesTextarea.focus()"
      classes="instabuy-modal-container"
      content-class="instabuy-modal-content instabuy-notes-modal"
      zIndex="1000000003"
    >
      <button class="instabuy-modal__close" @click="isShowingNotes = false">
        <svg focusable="false" width="2em" height="2em" viewBox="0 0 24 24">
          <path
            d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41z"
            fill="currentColor"
          ></path>
        </svg>
      </button>
      <span class="instabuy-modal__title" v-html="notesName"></span>
      <div class="instabuy-modal__content">
        <p
          class="notes-help-text"
          v-if="notesHelpText && notesHelpText.length > 0"
          v-html="notesHelpText"
        ></p>
        <textarea
          ref="notesTextarea"
          class="instabuy-notes-textarea"
          v-model="notes"
        ></textarea>
        <div class="checkout-button-container">
          <CheckoutButton
            :isLoading="isLoading"
            :translation="translation"
            :disabled="
              itemCount === 0 || (notesEnabled && notesRequired && notesIsBlank)
            "
            @checkout="checkout"
          />
        </div>
      </div>
    </vue-final-modal>

    <vue-final-modal
      name="checkout-thanks-modal"
      v-model="isShowingCheckoutThanks"
      classes="instabuy-modal-container"
      content-class="instabuy-modal-content instabuy-checkout-thanks-modal"
      zIndex="1000000004"
    >
      <button
        class="instabuy-modal__close"
        @click="isShowingCheckoutThanks = false"
      >
        <svg focusable="false" width="2em" height="2em" viewBox="0 0 24 24">
          <path
            d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41z"
            fill="currentColor"
          ></path>
        </svg>
      </button>
      <span class="instabuy-modal__title" v-html="checkoutThanksTitle"></span>
      <div class="instabuy-modal__content">
        <div
          class="checkout-thanks-text"
          v-html="formattedCheckoutThanksText"
        ></div>
      </div>
    </vue-final-modal>

    <component :is="'style'" v-if="cart.length > 0">
      #zonos, #chat-button { bottom: 90px !important; } @media screen and
      (max-width: 480px) { #zonos, #chat-button { bottom: 120px !important; } }
    </component>
    <div class="instabuy-order-details" v-if="expanded && cart.length > 0">
      <table class="instabuy-order-table">
        <tbody>
          <tr class="instabuy-checkout-error" v-if="checkoutError">
            <td colspan="4">
              <div class="instabuy-checkout-error-banner">
                <h2 v-html="checkoutError.title"></h2>
                <p v-html="checkoutError.content"></p>
              </div>
            </td>
          </tr>
          <CartLine
            @update-quantity="updateQuantity"
            @remove-line="removeLine"
            :moneyFormat="moneyFormat"
            :line="line"
            v-for="line of processedCart"
            :key="line.variant.id"
          />
        </tbody>
      </table>
    </div>
    <div class="instabuy-checkout-bar" v-if="cart.length > 0">
      <h2 class="instabuy-order-title">{{ translation.cart_title }}</h2>
      <p class="instabuy-order-item-count" @click="toggleExpanded">
        {{ itemCountText }}
      </p>
      <p
        class="instabuy-order-item-total"
        @click="toggleExpanded"
        v-html="formatMoney(itemTotal, moneyFormat)"
      ></p>
      <div class="instabuy-cart-break"></div>
      <button
        class="instabuy-checkout-button instabuy-order-toggle-expand"
        @click="toggleExpanded"
        v-html="expanded ? translation.cart_contract : translation.cart_expand"
      ></button>
      <CheckoutButton
        :isLoading="isLoading"
        :disabled="checkoutBarButtonDisabled"
        :translation="translation"
        @checkout="checkout"
      />
    </div>
  </div>
</template>

<script>
import { VueFinalModal, vfmPlugin } from "vue-final-modal";

import Vue from "vue";
import { EventBus } from "./EventBus.js";
import formatMoney from "./lib/format-money.js";
import cloneDeep from "lodash.clonedeep";
import calculatePrice from "./PricingCalculator.js";
import CheckoutButton from "./components/CheckoutButton.vue";
import CartLine from "./components/CartLine.vue";

const api = {
  development: `https://instabuy.test`,
  production: `https://app.useinstabuy.com`
}[process.env.NODE_ENV];

const instabuyCartVersion = 1;

Vue.use(vfmPlugin);

export default {
  components: { VueFinalModal, CheckoutButton, CartLine },

  data() {
    return {
      cart: [],
      expanded: false,
      isLoading: false,
      isShowingNotes: false,
      notes: "",
      cartVisible: false,
      isShowingCheckoutThanks: false
    };
  },

  props: [
    "customer",
    "pricingRules",
    "moneyFormat",
    "shopifyDomain",
    "notesEnabled",
    "notesName",
    "notesRequired",
    "notesHelpText",
    "translations",
    "checkoutAction",
    "checkoutThanksTitle",
    "checkoutThanksText"
  ],

  created() {},

  mounted() {
    EventBus.$on("show-instabuy-cart", this.showInstabuyCart);
    EventBus.$on("instabuy-update-line-quantity", this.updateQuantity);
    EventBus.$on("instabuy-add-to-cart", this.addToCartOne);
    EventBus.$on("instabuy-add-to-cart-all", this.addToCartArray);
    this.fetchCart();
  },

  destroyed() {
    EventBus.$off("show-instabuy-cart", this.showInstabuyCart);
    EventBus.$off("instabuy-update-line-quantity", this.updateQuantity);
    EventBus.$off("instabuy-add-to-cart", this.addToCartOne);
    EventBus.$off("instabuy-add-to-cart-all", this.addToCartArray);
    this.$el.remove();
  },

  computed: {
    translation() {
      return this.translations[0];
    },

    checkoutBarButtonDisabled() {
      if (this.itemCount === 0) {
        return true;
      }

      if (this.expanded) {
        return this.hasLineItemErrors || this.checkoutError;
      }

      return false;
    },

    formattedCheckoutThanksText() {
      return this.checkoutThanksText.replace(/\n\s*\n/g, "<br /><br />");
    },

    orderAttributes() {
      let attributes = {};
      if (this.notesEnabled && !this.notesIsBlank) {
        attributes[this.notesName || "Notes"] = this.notes;
      }
      return attributes;
    },

    processedCart() {
      const cart = [];

      for (var line of this.cart) {
        let newLine = cloneDeep(line);
        newLine.quantity = Math.max(0, newLine.quantity);
        newLine.minQuantity = this.lineQuantityProperty(line, "min_qty");
        newLine.maxQuantity = this.lineQuantityProperty(line, "max_qty");
        newLine.incrementQuantity = this.lineQuantityProperty(
          line,
          "increment"
        );
        newLine.errors = this.lineItemErrors(newLine);
        newLine.instabuyPrice = this.calculateInstabuyPrice(newLine);
        newLine.instabuyTotalPrice = newLine.instabuyPrice * newLine.quantity;
        cart.push(newLine);
      }

      return cart;
    },

    checkoutCart() {
      const cart = [];

      for (var line of this.cart) {
        let newLine = { variantId: line.variant.id, quantity: line.quantity };
        cart.push(newLine);
      }

      return cart;
    },

    itemCount() {
      let count = 0;
      for (var line of this.processedCart) {
        count += line.quantity;
      }
      return count;
    },

    itemCountText() {
      var unparsedText =
        this.itemCount === 1
          ? this.translation.cart_item
          : this.translation.cart_items;
      return unparsedText.replace(/\{\{\s*number\s*\}\}/, this.itemCount);
    },

    itemTotal() {
      let total = 0;
      for (var line of this.processedCart) {
        total += line.instabuyTotalPrice;
      }
      return total;
    },

    notesIsBlank() {
      return this.notes && this.notes.replace(/\s/g, "").length === 0;
    },

    customerMinimumOrderValue() {
      // pricing rules may not be loaded yet
      // console.log("pricingRules", this.pricingRules);
      if (!this.pricingRules.filter) {
        return 0;
      }
      var matchingGroups = this.pricingRules.filter(group => {
        if (group.customer_selection === "all_customers") {
          return true;
        }
        if (group.customer_selection === "logged_in" && customer.id) {
          return true;
        }
        if (group.customer_selection === "tags" && this.customer.tags) {
          for (var tag of this.customer.tags) {
            if (
              group.customer_tags
                .map(t => t.trim().toLowerCase())
                .indexOf(tag.trim().toLowerCase()) > -1
            ) {
              return true;
            }
          }
        }
        return false;
      });
      const compareNumbers = (a, b) => a - b;
      return matchingGroups
        .map(group => group.minimum_order_value)
        .sort(compareNumbers)[0];
    },

    hasLineItemErrors() {
      return (
        this.processedCart.filter(line => line.errors && line.errors.length > 0)
          .length > 0
      );
    },

    checkoutError() {
      let errors = [];
      if (
        this.customerMinimumOrderValue &&
        this.itemTotal < this.customerMinimumOrderValue
      ) {
        var unparsedText = this.translation.minimum_order_value_text;
        var parsedText = unparsedText.replace(
          /\{\{\s*amount\s*\}\}/,
          this.formatMoney(this.customerMinimumOrderValue, this.moneyFormat)
        );
        errors.push({
          title: this.translation.minimum_order_value_title,
          content: parsedText
        });
      }
      return errors[0];
    }
  },

  watch: {
    cart: {
      handler() {
        this.saveCart();
        this.$emit("cart-updated", this.cart);
        if (this.cart.length === 0) {
          this.expanded = false;
        }
      },
      deep: true
    },

    expanded() {
      if (this.expanded) {
        document.body.classList.add("instabuy-fullscreen");
      } else {
        document.body.classList.remove("instabuy-fullscreen");
      }
    },

    notes() {
      // update notes in localstorage
      let notesObj = {};
      notesObj.key = this.notesName;
      notesObj.value = this.notes;
      localStorage.setItem("instabuyNotes", JSON.stringify(notesObj));
    },

    // this is loaded remotely so we have to wait for it to change
    notesEnabled() {
      this.fetchNotes();
    }
  },

  methods: {
    showInstabuyCart() {
      this.cartVisible = true;
    },

    checkout() {
      // if we're not showing the order details and there is an error,
      // show the order details
      if (this.checkoutError || this.hasLineItemErrors) {
        if (!this.expanded) {
          this.expanded = true;
        }
        return;
      }

      if (this.notesEnabled) {
        if (!this.isShowingNotes) {
          this.isShowingNotes = true;
          return;
        }
      }

      this.isLoading = true;
      this.axios
        .post(`${api}/api/checkout`, {
          cart: this.checkoutCart,
          shop: this.shopifyDomain,
          customer: this.customer,
          attributes: this.orderAttributes
        })
        .then(response => {
          // We want the loading to carry through to the page redirect but not beyond in case of back click
          setTimeout(() => {
            this.isLoading = false;
          }, 5000);
          if (this.checkoutAction === "draft") {
            this.isShowingNotes = false;
            this.isShowingCheckoutThanks = true;
          } else {
            window.location.href = response.data.url;
          }
        })
        .catch(err => {
          this.isLoading = false;
        });
    },

    lineQuantityProperty(line, propName) {
      if (line.product.tags) {
        for (var tag of line.product.tags) {
          if (tag.indexOf(`${propName}:`) === 0) {
            if (tag.replace(`${propName}:`, "") > "") {
              return parseInt(tag.replace(`${propName}:`, ""));
            }
          }
        }
      }
      return null;
    },

    lineItemErrors(line) {
      let errors = [];
      if (line.minQuantity !== null && line.quantity < line.minQuantity) {
        errors.push("min");
      }
      if (line.maxQuantity !== null && line.quantity > line.maxQuantity) {
        errors.push("max");
      }
      if (
        line.incrementQuantity !== null &&
        line.quantity % line.incrementQuantity !== 0
      ) {
        errors.push("increment");
      }

      if (
        line.variant &&
        line.variant.inventory_quantity !== null &&
        line.quantity > line.variant.inventory_quantity
      ) {
        errors.push("available");
      }

      return errors;
    },

    removeLine(line) {
      this.cart = this.cart.filter(
        cline => cline.variant.id !== line.variant.id
      );
    },

    toggleExpanded() {
      this.expanded = !this.expanded;
      if (this.expanded) {
        this.cleanCart();
      }
    },

    cleanCart() {
      this.cart = this.cart.filter(li => li.quantity > 0);
    },

    calculateInstabuyPrice(line) {
      return calculatePrice(
        line.product,
        line.variant,
        this.pricingRules,
        this.customer
      );
    },

    updateQuantity(updatedLine, quantity) {
      this.cart.find(
        cartLine => cartLine.variant.id == updatedLine.variant.id
      ).quantity = +quantity;
    },

    pushToCart({ pricingRules, product, variantId, quantity }) {
      let productVariant = product.variants.find(v => v.id === variantId);
      let variant;
      if (productVariant) {
        variant = {
          id: productVariant.id,
          price: productVariant.price,
          title: product.variants.length > 1 ? productVariant.title : null,
          inventory_quantity:
            productVariant.inventory_policy === "deny" &&
            productVariant.inventory_management === "shopify"
              ? productVariant.inventory_quantity
              : null
        };
      }

      const productData = {
        id: product.id,
        title: product.title,
        tags: product.tags,
        handle: product.handle,
        collections: product.collections.map(collection => ({
          id: collection.id
        }))
        /*variants: product.variants.map((v) => {
          return {
            id: v.id,
            price: v.price,
            title: v.title,
          };
        }),*/
      };

      let existingLine = this.cart.find(
        existingLine => existingLine.variant.id === variantId
      );

      if (existingLine) {
        existingLine.quantity += +quantity;
      } else {
        this.cart.push({
          product: productData,
          variant,
          quantity: +quantity
        });
      }
    },

    addToCartOne({
      pricingRules,
      product,
      variantId,
      quantity,
      errorCallback
    }) {
      try {
        let result = this.pushToCart({
          pricingRules,
          product,
          variantId,
          quantity
        });
      } catch (e) {
        console.log(e);
        this.$vfm.show("atc-error-modal", e);
        // alert('could not add product to cart', e.quantityAdded)
      }
    },

    addToCartArray(productsArray, clearAddingToCart) {
      if (productsArray.length > 0) {
        for (let i = 1; i < productsArray.length; i++) {
          try {
            this.pushToCart(productsArray[i]);
          } catch (e) {}
        }
      }
    },

    showAddToCartError(err) {
      if (
        window.InstaBuy &&
        typeof window.InstaBuy.addToCartErrorMessage === "function"
      ) {
        window.InstaBuy.addToCartErrorMessage.call(this);
      } else if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.description
      ) {
        window.alert(err.response.data.description);
      }
    },

    fetchCart() {
      let data = localStorage.getItem("instabuyCart");
      if (data) {
        let cartData = JSON.parse(data);
        if (cartData.v === instabuyCartVersion) {
          this.cart = cartData.cart;
        }
        this.refreshCart();
      }
    },

    saveCart() {
      let cartData = {
        v: instabuyCartVersion,
        cart: this.cart
      };
      localStorage.setItem("instabuyCart", JSON.stringify(cartData));
    },

    refreshCart() {
      this.axios
        .post(`${api}/api/refresh_cart`, {
          cart: this.cart,
          shop: this.shopifyDomain
        })
        .then(response => {
          // console.log(response.data);
          if (response && response.data && response.data.cart) {
            this.cart = response.data.cart;
          }
        })
        .catch(err => {});
    },

    fetchNotes() {
      if (!this.notesEnabled) {
        return;
      }
      let data = localStorage.getItem("instabuyNotes");
      if (data) {
        try {
          let notesObj = JSON.parse(data);
          console.log(notesObj);
          if (this.notesName === notesObj.key) {
            this.notes = notesObj.value;
          }
        } catch (e) {}
      }
    },

    formatMoney
  }
};
</script>

<style lang="scss">
/* Hide theme cart icon */
.header__cart-dot,
.header__nav-icon.icon-cart {
  display: none;
}

.vfm__overlay:empty {
  display: block; // to overcome div:empty styling on themes :-/
}

.instabuy-notes-textarea {
  width: 100%;
  min-height: 150px;
}

.instabuy-notes-modal {
  .checkout-button-container {
    display: flex;
    justify-content: flex-end;
    button {
      margin: 0;
    }
  }
  .notes-help-text {
    margin-bottom: 0.5rem;
    color: #666;
    font-size: 16px;
  }
}

@media screen and (min-width: 481px) {
  .instabuy-notes-textarea {
    width: 50vw;
  }
}

.instabuy-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.instabuy-modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.instabuy-modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}
.instabuy-modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: none;
  border: none;
}

body.instabuy-fullscreen {
  overflow: hidden;
}

.instabuy-order-details {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  padding: 20px 20px 120px;
  z-index: 1000000000;
  overflow-y: scroll;
  table.instabuy-order-table {
    border: none;
    width: 100%;
    margin: 0 auto;
    max-width: 960px;
    background: transparent;
    border-bottom: 1px solid #eee;

    tr,
    td,
    th {
      border: none;
    }
    tr {
      border-top: 1px solid #eee;
    }
    tr.instabuy-table-line-item-error-row {
      border-top: none;
    }
    td {
      vertical-align: top;
    }
    .instabuy-line-item-error {
      color: #c5280c;
      font-size: 14px;
    }
    td.instabuy-table-line-item-title {
      @media screen and (min-width: 481px) {
        .instabuy-line-item-quantity {
          display: none;
        }
      }
    }
    td.instabuy-table-line-item-quantity,
    td.instabuy-table-line-item-price,
    td.instabuy-table-line-item-remove {
      width: 1%;
    }
    td.instabuy-table-line-item-price {
      line-height: 30px;
    }
    @media screen and (min-width: 481px) {
      td.instabuy-table-line-item-price {
        min-width: 6em;
      }
      .instabuy-table-line-item-mobile-quantity {
        display: none;
      }
    }
    .instabuy-table-line-item-mobile-quantity {
      margin-top: 8px;
    }
    .instabuy-checkout-error-banner {
      background: #fde2dd;
      border: 1px solid #c5280c;
      border-radius: 4px;
      padding: 10px;
      h2 {
        text-transform: none;
        letter-spacing: 0;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
        color: #333;
      }
      p {
        font-size: 14px;
        color: #333;
        margin: 0;
      }
    }
  }
  .instabuy-table-line-item-quantity-wrapper {
    display: flex;
  }
  input.instabuy-line-item-quantity {
    width: 3rem;
    padding: 6px 10px;
    border: 1px solid #666;
    height: 32px;
    border-radius: 0;

    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &.instabuy-line-item-quantity-error {
      background: #fde2dd;
      border: 1px solid #c5280c;
    }
  }
  button.instabuy-table-quantity-button {
    height: 32px;
    width: 16px;
    border: 1px solid #666;
    background: white;
    &.instabuy-table-quantity-button-dec {
      border-right: none;
      border-radius: 3px 0 0 3px;
    }
    &.instabuy-table-quantity-button-inc {
      border-left: none;
      border-radius: 0 3px 3px 0;
    }
  }
  button.instabuy-remove-line-item {
    border-radius: 100px;
    background: #333;
    width: 30px;
    height: 30px;
    color: white;
    font-weight: bold;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.instabuy-checkout-bar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000000002;
  background: white;
  box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.25);
  .instabuy-order-item-count {
    background: #008060;
    color: #fff;
    border-radius: 100px;
    vertical-align: middle;
    padding: 2px 12px;
    height: 28px;
    font-size: 16px;
    margin-right: 20px;
    margin-bottom: 0;
    margin-top: 0;
    line-height: 24px;
    cursor: pointer;
  }
  .instabuy-order-item-total {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 20px;
    cursor: pointer;
  }
  h2.instabuy-order-title {
    flex-grow: 1;
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 20px;
    font-size: 16px;
  }
}

button.instabuy-checkout-button {
  border-radius: 5px;
  border: none;
  padding: 8px 24px;
  height: auto;
  margin: 0 10px;
  font-size: 16px;
  &.instabuy-order-toggle-expand {
    background-color: #fff;
    border: 2px solid #008060;
    color: #333;
  }
  &.instabuy-checkout {
    border: 2px solid #008060;
    background: #008060;
    color: white;
    font-weight: bold;
    position: relative;
    svg {
      width: 50%;
      height: 50%;
      position: absolute;
      left: 25%;
      top: 30%;
    }
    &.is-loading {
      .instabuy-button-text {
        visibility: hidden;
      }
    }
    &:disabled {
      background-color: #f0f0f0;
      border: 2px solid #f0f0f0;
      color: #999;
    }
  }
}

button.instabuy-order-contract {
  position: fixed;
  z-index: 1000000001;
  right: 20px;
  top: 20px;
  background: transparent;
  border: none;
  font-size: 80px;
}

@media screen and (max-width: 480px) {
  .instabuy-cart-break {
    flex-basis: 100%;
    height: 10px;
  }

  .instabuy-checkout-bar button {
    flex-grow: 1;
  }

  button.instabuy-order-toggle-expand {
    margin-left: 0;
  }

  button.instabuy-checkout {
    margin-right: 0;
  }

  td.instabuy-table-line-item-quantity {
    display: none;
  }
}
</style>
