var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],class:[
    'widget-product-main',
    'widget-variant-main',
    _vm.isOutOfStock ? 'instabuy-out-of-stock' : ''
  ]},[_c('td',{staticClass:"widget-product-image widget-variant-image",attrs:{"rowspan":"2"}},[(_vm.variant.featured_image)?_c('Luminous',{attrs:{"thumbnail":_vm.variant.featured_image,"image":_vm.variant.featured_image}}):_vm._e()],1),_c('td',{staticClass:"widget-product-title widget-variant-title",attrs:{"colspan":"3"}},[_c('h4',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.highlight(_vm.variantTitle, _vm.highlightSearchQuery))}}),_c('p',{staticClass:"widget-product-price"},[(
            _vm.variant.compare_at_price && _vm.widget.showCompareAtPrice === true
          )?_c('span',{staticClass:"widget-compare-at-price",attrs:{"data-price":_vm.variant.compare_at_price},domProps:{"innerHTML":_vm._s(
            typeof _vm.variant.formatted_compare_at_price === 'undefined'
              ? _vm.formatMoney(_vm.variant.compare_at_price, _vm.widget.moneyFormat)
              : _vm.variant.formatted_compare_at_price
          )}}):_vm._e(),_c('span',{staticClass:"widget-current-price",attrs:{"data-price":_vm.variant.price},domProps:{"innerHTML":_vm._s(_vm.price)}}),(_vm.showWeight || _vm.showAvailability)?_c('span',{staticClass:"widget-product-properties"},[(_vm.showAvailability)?_c('span',{staticClass:"widget-available",domProps:{"innerHTML":_vm._s(_vm.quantityAvailableText)}}):_vm._e(),(_vm.showWeight && _vm.showAvailability)?_c('small',[_vm._v(" • ")]):_vm._e(),(_vm.showWeight)?_c('span',{staticClass:"widget-weight"},[_vm._v(" "+_vm._s(_vm.variant.weight_in_unit)+_vm._s(_vm.variant.weight_unit)+" ")]):_vm._e()]):_vm._e()])]),(_vm.showCartQuantity && _vm.quantityInCart > 0)?_c('p',[_vm._v(" "+_vm._s(_vm.quantityInCart)+" in cart ")]):_vm._e(),(_vm.widget.showSku === true && _vm.variant.sku)?_c('p',{staticClass:"widget-product-sku widget-variant-sku",domProps:{"innerHTML":_vm._s(_vm.highlight(_vm.variant.sku, _vm.highlightSearchQuery))}}):_vm._e()]),_c('ProductQuantityCell',{attrs:{"value":_vm.quantity,"hasMultipleVariants":false,"quantityInputStyle":_vm.quantityInputStyle,"variant":_vm.variant,"product":_vm.product,"widget":_vm.widget,"buttonBorderRadius":_vm.widget.buttonBorderRadius,"showQuantityButtons":_vm.widget.showQuantityButtons,"showQuantityInput":_vm.widget.showQuantityInput},on:{"input":_vm.updateQuantity}}),_c('ProductButtonCell',{attrs:{"hasMultipleVariants":false,"variant":_vm.variant,"product":_vm.product,"widget":_vm.widget,"quantity":_vm.quantity},on:{"add-to-cart":function($event){return _vm.$emit('add-to-cart', $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }