<template>
  <div class="collection-pane" :data-collection-handle="collection.handle">
    <h3
      v-if="showCollectionTitle"
      class="widget-collection-title"
      v-html="collection.title"
    ></h3>
    <p
      v-if="showCollectionDescription"
      class="widget-collection-description"
      v-html="collection.description"
    ></p>
    <ProductTable
      :stagedProducts="stagedProducts"
      :widget="widget"
      :productCount="filteredProducts ? filteredProducts.length : 0"
    >
      <Product
        v-for="product in filteredProducts"
        :product="product"
        :key="product.id"
        :widget="widget"
        :stagedProducts="stagedProducts"
        :pricingRules="pricingRules"
        :customer="customer"
        :highlightSearchQuery="highlightSearchQuery"
        :cart="cart"
        @add-to-cart="$emit('add-to-cart', $event)"
        @re-rendered="$emit('re-rendered', $event)"
      ></Product>
    </ProductTable>
    <Pagination
      v-if="pageCount > 1"
      :widget="widget"
      :pageCount="pageCount"
      :isLoading="isLoading"
      :currentPage="currentPage"
      @load-page="loadPage($event)"
    ></Pagination>
    <div v-if="isLoading" class="widget-products-spinner">
      <div class="widget-spinner"></div>
    </div>
  </div>
</template>

<script>
import Product from "./Product.vue";
import ProductTable from "./ProductTable.vue";
import Pagination from "./Pagination.vue";
import Vue from "vue";

export default {
  props: [
    "title",
    "collection",
    "widget",
    "visible",
    "stagedProducts",
    "pricingRules",
    "customer",
    "highlightSearchQuery",
    "cart",
  ],

  components: {
    Product,
    ProductTable,
    Pagination,
  },

  data() {
    return {
      products: [],
      productsCount: null,
      fetchedPages: [],
      pageCount: null,
      currentPage: 1,
      isLoading: true,
      fetched: false,
    };
  },

  created() {
    if (this.visible) {
      this.fetchPage(1);
    }
  },

  computed: {
    useShopifySearch() {
      return !(window.InstaBuy && window.InstaBuy.useCustomSearch);
    },

    showCollectionTitle() {
      return this.widget.navFormat === "list";
    },

    showCollectionDescription() {
      if (
        window.InstaBuy &&
        window.InstaBuy.showCollectionDescription === false
      ) {
        return false;
      }
      return !!this.collection.description;
    },

    filteredProducts() {
      if (
        window.InstaBuy &&
        (typeof window.InstaBuy.filterVariant === "function" ||
          typeof window.InstaBuy.filterProduct === "function")
      ) {
        var filteredProducts = [];
        var products = this.fetchedPages[this.currentPage];
        if (typeof products === "undefined") {
          return [];
        }
        for (var i = 0; i < products.length; i++) {
          var product = products[i];
          var filteredVariants = [];
          for (var j = 0; j < product.variants.length; j++) {
            if (
              typeof window.InstaBuy.filterVariant !== "function" ||
              window.InstaBuy.filterVariant.call(
                this,
                product.variants[j],
                this.highlightSearchQuery
              ) !== false
            ) {
              filteredVariants.push(product.variants[j]);
            }
          }
          if (filteredVariants.length > 0) {
            product.variants = filteredVariants;
            if (typeof window.InstaBuy.filterProduct === "function") {
              if (
                window.InstaBuy.filterProduct.call(
                  this,
                  product,
                  this.highlightSearchQuery
                )
              ) {
                filteredProducts.push(product);
              }
            } else {
              filteredProducts.push(product);
            }
          }
        }
      } else {
        var filteredProducts = this.fetchedPages[this.currentPage];
      }
      return filteredProducts;
    },
  },

  watch: {
    visible() {
      if (this.visible && !this.fetched) {
        this.fetchPage(1);
      }
    },
  },

  methods: {
    fetchPage(page) {
      this.fetched = true;
      this.isLoading = true;
      fetch(
        `${this.widget.proxiedShopUrl}/collections/${this.collection.handle}?page=${page}&view=instabuy-data`
      )
        .then((response) => response.json())
        .then((data) => {
          if (window.InstaBuy.paginateCollections) {
            this.pageCount = data.pages;
            this.productsCount = data.items;
            this.$set(this.fetchedPages, page, data.products);
            this.isLoading = false;
          } else {
            this.$set(
              this.fetchedPages,
              1,
              (this.fetchedPages[1] || []).concat(data.products)
            );
            this.productsCount = data.items;
            if (page < data.pages) {
              this.fetchPage(page + 1);
            } else {
              this.isLoading = false;
            }
            this.$emit("re-rendered");
          }
        });
    },

    loadPage(page) {
      this.currentPage = page;
      if (!this.fetchedPages[page]) {
        this.fetchPage(page);
      }
      this.$emit("scroll-to-widget-top");
    },
  },
};
</script>

<style scoped>
h3.widget-collection-title {
  font-size: 22px;
  text-transform: none;
  letter-spacing: 0;
  margin-bottom: 15px;
  font-weight: bold;
  margin-top: 0;
}
</style>
